import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpButton from "../components/Button/SpButton";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import useUsa027DBActions from "../hooks/pages/USA027/useUsa027DBActions";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import PatientFooter from "../features/PatientFooter";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useEmailInput, {
  checkEmailInputError,
  setEmail,
} from "../hooks/input/useEmailInput";
import usePath from "../hooks/common/usePath";
import useSubscriptionPlansFetch from "../hooks/common/useSubscriptionPlansFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PAYMENT_PERIOD } from "../constants/common";
import { convertToCommaSeparatedString } from "../utils/utils";

// サブスクのラジオボタンアイテムを取得
const getSubscriptionPlanItems = (
  plans: SubscriptionPlansType<typeof PAYMENT_PERIOD>[],
) =>
  plans.map((plan) => ({
    label: (
      <LayoutBox direction="column" gap="1x">
        <Text bold size="xl">
          {plan?.span === PAYMENT_PERIOD.monthlyPayment
            ? "月払いコース"
            : "年払いコース"}
        </Text>
        <LayoutBox align="center" gap="1/2x">
          <Text color="error" size="xl" bold>
            {plan?.price ? convertToCommaSeparatedString(plan?.price) : ""}円
          </Text>
          <Text color="error" size="large">
            {plan?.span === PAYMENT_PERIOD.monthlyPayment ? "/月" : "/年"}
          </Text>
        </LayoutBox>
        <Text>
          初回会費のお支払いとサービスのご利用開始は承認後となります。
        </Text>
      </LayoutBox>
    ),
    value: String(plan?.span),
  }));

const USA027 = () => {
  const { createInvitation } = useUsa027DBActions();
  const navigate = useNavigateWithUrl();
  const navigateState = useNavigate();
  const [emailInputs, setEmailInputs] = useState<USA027Type[]>([
    {
      email: "",
      paymentPlan: "",
      isError: false,
      validationMessage: "",
    },
  ]);
  const [payment, setPayment] = useState<string[]>([""]);
  const [{ email }] = useEmailInput();
  const [path] = usePath();
  const { fetchError, subscriptionPlans } = useSubscriptionPlansFetch();

  // エラー処理
  useCheckErrorThrowError([fetchError]);

  const addEmailInput = () => {
    setEmailInputs([
      ...emailInputs,
      {
        email: "",
        paymentPlan: "",
        isError: false,
        validationMessage: "",
      },
    ]);
    const addPayment = [...payment, ""];
    setPayment(addPayment);
  };

  const onSetPayment = (index: number, value: string) => {
    const updatedPayment = [...payment];
    updatedPayment[index] = value;
    setPayment(updatedPayment);
  };

  const removeEmailInput = (index: number) => {
    const updatedEmailInputs = [...emailInputs];
    const updatedPayment = [...payment];
    updatedEmailInputs.splice(index, 1);
    updatedPayment.splice(index, 1);
    setEmailInputs(updatedEmailInputs);
    setPayment(updatedPayment);
  };
  // 戻るボタン
  const handleGoBack = () => navigate(path);

  // 送信ボタン押下
  const onNextButtonClicked = () => {
    void (async () => {
      const transformedEmailInputs = emailInputs.map(
        ({ email, paymentPlan }) => ({
          email,
          payment_plan: paymentPlan,
        }),
      );
      const isSuccess = await createInvitation(transformedEmailInputs);
      if (!isSuccess) return;
      navigateState(PATIENT.USA028, { state: { emailInputs } });
    })();
  };

  const handleEmailChange = (newValue: string) => {
    setEmail(newValue);
  };

  const checkEmailPaymentInputError = (): boolean => {
    const isEmail = checkEmailInputError();
    if (isEmail) return true;

    const isEmailInput = emailInputs.some(({ email }) => email === "");
    if (isEmailInput) return true;

    const isPayment = emailInputs.some(({ paymentPlan }) => paymentPlan === "");
    if (isPayment) return true;

    return false;
  };

  return (
    <div className="admin-area admin-area_no-header">
      <HeaderUser
        withRightControl={path === INVITATION.USA030 || path === PATIENT.USB001}
      />
      <div className="admin-inner area-with-header-footer">
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="招待"
          borderBottom="primary"
          withButton={false}
        />
        <div className="util-overflow-x">
          <Sheet padding="24px 0">
            <LayoutBox gap="3x" direction="column">
              <Sheet
                type="card"
                padding="16px 32px 32px 32px"
                maxWidth="1064px"
              >
                <LayoutBox direction="column" gap="3x" fullWidth>
                  <LayoutBox direction="column" fullWidth>
                    <Text size="2xl" bold>
                      招待
                    </Text>
                    <Divider borderWidth="3px" margin="0" />
                  </LayoutBox>
                  <LayoutBox direction="column" gap="1x" fullWidth>
                    <LayoutBox
                      direction="column"
                      gap="1/2x"
                      minWidth="400px"
                      maxWidth="774px"
                      className="util-sp-full-width"
                      justify="end"
                      fullHeight
                    >
                      <Text size="xl" width="100%">
                        招待したい方のメールアドレスを入力し、お支払いコースを選択してください。
                        <br />
                        入力されたメールアドレス宛に、招待コードと登録用URLを送信します。
                        <br />
                        招待メールから会員登録が行われるとメッセージ通知が届きますので、承認をお願いします。
                      </Text>
                    </LayoutBox>
                    <SpFormSet
                      label="招待枠"
                      base
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                      labelGap="large"
                    >
                      {emailInputs.map((emailInput, index) => (
                        <Sheet
                          key={index}
                          withBorderRadius
                          type="border"
                          padding="16px"
                        >
                          <LayoutBox direction="column" gap="2x">
                            <LayoutBox
                              direction="column"
                              gap="1/2x"
                              minWidth="400px"
                              maxWidth="774px"
                              className="util-sp-full-width"
                              justify="end"
                              fullHeight
                              fullWidth
                            >
                              <SpFormSet
                                key={index}
                                label={`メールアドレス${index + 1}`}
                                labelGap="small"
                                labelWidth="775px"
                                spToColumnAndFullWidth
                                required="badge"
                                vertical
                                errorTextList={[emailInput.validationMessage]}
                              >
                                <LayoutBox gap="1x" fullWidth>
                                  <Input
                                    width="311px"
                                    value={emailInput.email}
                                    error={emailInput.isError}
                                    onChange={(value: string) => {
                                      handleEmailChange(value);
                                      const updatedEmailInputs = [
                                        ...emailInputs,
                                      ];
                                      updatedEmailInputs[index].email = value;
                                      updatedEmailInputs[index].isError =
                                        email.isError;
                                      updatedEmailInputs[
                                        index
                                      ].validationMessage =
                                        email.validationMessage;
                                      setEmailInputs(updatedEmailInputs);
                                    }}
                                  />
                                  <Button
                                    type="secondary"
                                    size="small"
                                    width="40px"
                                    height="40px"
                                    shape="circle"
                                    icon="close"
                                    onClick={() => removeEmailInput(index)}
                                  />
                                </LayoutBox>
                              </SpFormSet>
                            </LayoutBox>
                            <RadioButtonGroup
                              name={`RadioGender-${index}`}
                              items={getSubscriptionPlanItems(
                                subscriptionPlans,
                              )}
                              onChange={(value) => {
                                const plan =
                                  value === "1" ? "monthly" : "yearly";
                                const updatedEmailInputs = [...emailInputs];
                                updatedEmailInputs[index].paymentPlan = plan;
                                setEmailInputs(updatedEmailInputs);
                                onSetPayment(index, value);
                              }}
                              selectedValue={payment[index]}
                              withBorder
                              width="100%"
                              radioGap="8px"
                            />
                          </LayoutBox>
                        </Sheet>
                      ))}

                      <div className="util-mb-8" />
                      <SpButton
                        type="primary"
                        width="200px"
                        size="small"
                        icon="add"
                        onClick={addEmailInput}
                      >
                        招待枠を追加
                      </SpButton>
                    </SpFormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <LayoutBox
                minWidth="1064px"
                justify="between"
                gap="2x"
                className="util-sp-full-width"
                wrapReverse
              >
                <SpButton
                  type="secondary"
                  width="400px"
                  size="medium"
                  mobileFullWidth
                  iconSize="large"
                  withArrowLeft
                  onClick={handleGoBack}
                >
                  戻る
                </SpButton>
                <SpButton
                  type="primary"
                  width="400px"
                  size="medium"
                  withArrowRight
                  mobileFullWidth
                  onClick={() => {
                    void onNextButtonClicked();
                  }}
                  disabled={checkEmailPaymentInputError()}
                >
                  送信
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </div>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA027;
