import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import useGetPaidInterviewsCardInfo from "../hooks/pages/USD004/useGetPaidInterviewsCardInfo";

const PaidInterviewCard = () => {
  const interviewsCardInfo = useGetPaidInterviewsCardInfo();

  return (
    <Card
      header={
        <>
          <Text bold size="xl">
            オンライン面談(別途料金)
          </Text>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <>
          <LayoutBox direction="column" gap="1x">
            <Text size="large" color="neutralUserLight">
              {interviewsCardInfo.content}
            </Text>
            {interviewsCardInfo.shouldShowZoom ? (
              <Text size="large" color="neutralUserLight">
                zoom
              </Text>
            ) : (
              <></>
            )}
            {interviewsCardInfo.shouldShowTag && (
              <Tag
                label={interviewsCardInfo.shouldShowTag.label}
                state={interviewsCardInfo.shouldShowTag.state}
                showIcon={false}
              />
            )}
          </LayoutBox>
        </>
      }
      onClick={interviewsCardInfo.onClick}
      footerColor={interviewsCardInfo.footerColor}
      footerLabel={interviewsCardInfo.footerLabel}
      footerText={interviewsCardInfo.footerText}
      minHeight="mh-50px"
    />
  );
};
export default PaidInterviewCard;
