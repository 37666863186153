import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import {
  UserStatusID,
  PlanSpanID,
  PATIENT_INVITATIONS_STATUS,
} from "../constants/common";
import { INVITATION } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { setPath } from "../hooks/common/usePath";
import SpButton from "../components/Button/SpButton";
import InformationDialog from "../components/Dialog/InformationDialog";
import { setCancelEmailConditions } from "../hooks/common/useCancelEmailConditions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useCancelPresent from "../hooks/common/useCancelPresent";

type InvitationUserCardProps = {
  registered: PatientInvitation[];
  unregistered: PatientInvitation[];
};

const InvitationUserCard: React.FC<InvitationUserCardProps> = ({
  registered,
  unregistered,
}) => {
  const { cancelPresent, error: invitationCancelError } = useCancelPresent();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelEmail, setCancelEmail] = useState("");

  // エラー処理
  useCheckErrorThrowError([invitationCancelError]);

  const isPaymentError = (status: number) =>
    status === PATIENT_INVITATIONS_STATUS.paymentError;

  // アカウントカード押下
  const handleAccountButtonClicked = (id: string, status: number) => {
    setId(id);
    setPath(INVITATION.USA029);
    const route = status === 1 ? INVITATION.USA032 : INVITATION.USA031;
    navigate(route);
  };

  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // キャンセルダイアログopen
  const handleCancelConfirm = (email: string) => {
    setCancelEmail(email);
    setIsOpen(true);
  };

  // キャンセルボタン
  const handleCancel = () => {
    void (async () => {
      const isSuccess = await cancelPresent(cancelEmail);
      if (!isSuccess) return;
      setIsOpen(false);

      setCancelEmailConditions(cancelEmail);
      navigate(INVITATION.USA038);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  const labelStatus = (status: number) => {
    switch (status) {
      case PATIENT_INVITATIONS_STATUS.notRegistered:
        return "未登録";
      case PATIENT_INVITATIONS_STATUS.currentlyApplying:
        return "申請中";
      case PATIENT_INVITATIONS_STATUS.registered:
        return "登録済み";
      case PATIENT_INVITATIONS_STATUS.denial:
        return "否認";
      case PATIENT_INVITATIONS_STATUS.withdrawal:
        return "退会済み";
      case PATIENT_INVITATIONS_STATUS.cancel:
        return "キャンセル";
      case PATIENT_INVITATIONS_STATUS.paymentError:
        return "決済エラー";
      default:
        return "未登録";
    }
  };

  const iconStatus = (status: number) => {
    switch (status) {
      case PATIENT_INVITATIONS_STATUS.notRegistered:
        return "neutral";
      case PATIENT_INVITATIONS_STATUS.currentlyApplying:
        return "warning";
      case PATIENT_INVITATIONS_STATUS.registered:
        return "success";
      case PATIENT_INVITATIONS_STATUS.denial:
      case PATIENT_INVITATIONS_STATUS.withdrawal:
      case PATIENT_INVITATIONS_STATUS.cancel:
      case PATIENT_INVITATIONS_STATUS.paymentError:
        return "error";
      default:
        return "neutral";
    }
  };

  return (
    <>
      <LayoutBox direction="column" gap="1x" fullWidth>
        {registered?.map((data, index) =>
          data.status === PATIENT_INVITATIONS_STATUS.currentlyApplying ||
          data.status === PATIENT_INVITATIONS_STATUS.registered ||
          data.status === PATIENT_INVITATIONS_STATUS.paymentError ? (
            <Card
              key={index}
              onClick={() =>
                handleAccountButtonClicked(data.invited_patient_id, data.status)
              }
              header={
                <LayoutBox gap="1x" align="center">
                  <Text bold size="large">
                    {data.name}
                  </Text>
                  <Tag
                    label={labelStatus(data.status)}
                    state={iconStatus(data.status)}
                    emphasis={isPaymentError(data.status)}
                  />
                </LayoutBox>
              }
              content={
                <LayoutBox gap="1x" align="start" direction="column">
                  <Text size="large" color="neutralUserLight">
                    {data.email}
                    {!isPaymentError(data.status) && (
                      <>
                        <br />
                        {data.subscriptionSpan === PlanSpanID.YEAR
                          ? "年払いコース"
                          : "月払いコース"}
                        <br />
                        次回更新日: {data.nextBillingDateStr}
                      </>
                    )}
                  </Text>
                  {isPaymentError(data.status) && (
                    <Text size="large" color="error">
                      カード決済が正常に行えていません
                    </Text>
                  )}
                </LayoutBox>
              }
            />
          ) : (
            <Card
              key={index}
              header={
                <LayoutBox gap="1x" align="center">
                  <Text bold size="large">
                    {data.email}
                  </Text>
                  <Tag
                    label={labelStatus(data.status)}
                    state={iconStatus(data.status)}
                    icon={
                      data.status === UserStatusID.UNREGISTERED ? "" : "block"
                    }
                  />
                </LayoutBox>
              }
              clickable={false}
            />
          ),
        )}
        {unregistered?.map((data, index) => (
          <Card
            key={index}
            type="button"
            buttonItem={
              data.status === UserStatusID.UNREGISTERED
                ? {
                    label: "招待をキャンセル",
                    width: "311px",
                  }
                : undefined
            }
            clickable={false}
            buttonType="secondary"
            onButtonItemClick={() => handleCancelConfirm(data.email)}
            header={
              <LayoutBox gap="1x" align="center">
                <Text bold size="large">
                  {data.email}
                </Text>
                <Tag
                  label={labelStatus(data.status)}
                  state={iconStatus(data.status)}
                  icon={
                    data.status === UserStatusID.UNREGISTERED ? "" : "block"
                  }
                />
              </LayoutBox>
            }
          />
        ))}
      </LayoutBox>
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="招待を取り消してもよろしいですか？"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton width="275px" onClick={handleCancel}>
                  招待を取り消す
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">この操作は取り消せません。</Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </>
  );
};

export default InvitationUserCard;
