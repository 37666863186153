import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";

import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { INVITATION } from "../constants/pagePaths";
import useUsa032Actions from "../hooks/pages/USA032/useUsa032DBActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import PatientFooter from "../features/PatientFooter";

const USA035 = () => {
  const navigate = useNavigateWithUrl();
  const { result, error } = useUsa032Actions();

  // エラー処理
  useCheckErrorThrowError([error]);

  // 前画面に戻る
  const handleClickBack = () => navigate(INVITATION.USA030);

  const handleClick = () => navigate(INVITATION.USA029);

  return (
    <div className="admin-area  user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header={result?.name}
          content={result?.email}
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <LayoutBox justify="center">
          <Sheet maxWidth="1112px">
            <Sheet type="card">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="center" gap="2x">
                  <Icon color="#A21B11" icon="block" size="xl" />
                  <Text bold size="2xl">
                    否認しました
                  </Text>
                </LayoutBox>
                <SpButton
                  size="medium"
                  mobileFullWidth
                  withArrowRight
                  onClick={handleClick}
                >
                  マイページへ
                </SpButton>
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA035;
