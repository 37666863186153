import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const TopNouknowCard = () => {
  const navigate = useNavigateWithUrl();

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="center"
            align="start"
            flexShrink={0}
            direction="column"
          >
            <Text bold size="xl">
              のうKNOW®
            </Text>
            <Text size="large">オンライン認知機能検査</Text>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          3か月ごとに受検してください。
        </Text>
      }
      onClick={() => navigate(PATIENT.USC001)}
    />
  );
};
export default TopNouknowCard;
