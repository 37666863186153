import { useLocation } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import SpButton from "../components/Button/SpButton";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useUsa028DBActions from "../hooks/pages/USA028/useUsa028DBActions";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import usePath from "../hooks/common/usePath";

type LocationState = {
  emailInputs: Array<USA027Type>;
};

const USA028 = () => {
  const location = useLocation();
  const { emailInputs } = location.state as LocationState;
  const { result } = useUsa028DBActions(emailInputs);
  const navigate = useNavigateWithUrl();
  const [path] = usePath();

  const onNextButtonClicked = () => {
    if (path === PATIENT.USB001) {
      navigate(PATIENT.USB001);
    } else {
      navigate(INVITATION.USA029);
    }
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser
          withRightControl={
            path === INVITATION.USA030 || path === PATIENT.USB001
          }
        />
        <div className="admin-inner area-with-header-footer">
          <div className="admin-inner area-with-header-footer">
            <Title
              padding="16px 108px 24px 108px"
              background="default"
              header="招待"
              withButton={false}
              borderBottom="primary"
            />
            <Sheet padding="24px" className="util-sp-py-24">
              <LayoutBox direction="column" maxWidth="440px" width="440px">
                <Sheet
                  type="card"
                  padding="24px 16px"
                  className="util-sp-pt-16"
                >
                  <LayoutBox
                    gap="3x"
                    direction="column"
                    align="center"
                    fullWidth
                  >
                    <LayoutBox direction="column" gap="2x" align="center">
                      <Icon color="#388e3c" icon="check_circle" size="xl" />
                      <Text
                        bold
                        size="2xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        招待が完了しました
                      </Text>
                      <Text
                        size="xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        招待メールから会員登録が行われると、メッセージ通知が届きます。通知が届いたら承認をお願いします。
                      </Text>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </Sheet>
            <Sheet padding="2px 10px 40px">
              <LayoutBox direction="column" maxWidth="440px" width="440px">
                <Sheet
                  type="card"
                  padding="24px 16px"
                  className="util-sp-pt-16"
                >
                  <LayoutBox
                    gap="4x"
                    direction="column"
                    align="center"
                    fullWidth
                  >
                    {result.map((data, index) => (
                      <LayoutBox fullWidth direction="column" key={index}>
                        <LayoutBox gap="1x" fullWidth direction="column">
                          <Text
                            size="large"
                            color="neutralLight"
                            lineHeight="100%"
                          >
                            メールアドレス{index + 1}
                          </Text>
                          <Text
                            size="large"
                            color="neutralUserBlack"
                            lineHeight="100%"
                          >
                            {data.email}
                          </Text>
                          <Divider width="100%" darkColor orange={false} />
                        </LayoutBox>
                        <Sheet
                          type="border-blue"
                          color="secondary-lightest"
                          borderRadius="8px"
                          padding="16px 16px"
                        >
                          <LayoutBox fullWidth justify="between">
                            <Text textAlign="left" size="large">
                              招待コード
                            </Text>
                            <Text bold textAlign="right" size="large">
                              {data.code}
                            </Text>
                          </LayoutBox>
                        </Sheet>
                      </LayoutBox>
                    ))}
                  </LayoutBox>
                </Sheet>
                <div className="util-mt-40" />
                <LayoutBox fullWidth justify="center">
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    mobileFullWidth
                    withArrowRight
                    onClick={onNextButtonClicked}
                  >
                    トップへ
                  </SpButton>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </div>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USA028;
