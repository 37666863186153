import React from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Tabs from "../components/Tab/Tabs";
import Title from "../components/Title/Title";

import Cart from "../assets/tabs-icons/cart-icon.svg";
import ChatBubble from "../assets/tabs-icons/chat-bubble-icon.svg";
import House from "../assets/tabs-icons/house-icon.svg";
import Notebook from "../assets/tabs-icons/notebook-icon.svg";
import Vaudeville from "../assets/tabs-icons/vaudeville-icon.svg";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpDropdown from "../components/Dropdown/SpDropdown";
import Divider from "../components/Divider/Divider";
import SpButton from "../components/Button/SpButton";
import Badge from "../components/Badge/Badge";
import PatientFooter from "../features/PatientFooter";

const TAB_ITEMS = [
  { title: "トップ", image: House },
  { title: "検査", image: Notebook, badge: "結果" },
  { title: "健康相談", image: ChatBubble, badge: "1" },
  {
    title: "オンライン寄席",
    titleMobile: "寄席",
    image: Vaudeville,
    badge: "新着",
  },
  { title: "ショップ", image: Cart, badge: "1" },
];

const DROPDOWN_PREFECTURES = [{ value: "東京", label: "東京" }];
const DROPDOWN_HOSPITALS = [{ value: "〇〇病院", label: "〇〇病院" }];
const DROPDOWN_EMPTY = [
  {
    value: "9:00〜10:00",
    label: "9:00〜10:00",
  },
  {
    value: "11:00〜12:00",
    label: "11:00〜12:00",
  },
  {
    value: "13:00〜14:00",
    label: "13:00〜14:00",
  },
  {
    value: "15:00〜16:00",
    label: "15:00〜16:00",
  },
  {
    value: "17:00〜18:00",
    label: "17:00〜18:00",
  },
];

const USC008 = () => {
  const [prefecture, setPrefecture] = React.useState<{
    value: string;
    label: string;
  }>(DROPDOWN_PREFECTURES[0]);
  const [candidateCount, setCandidateCount] = React.useState(3);

  const handleChangeFilter = (value: string) => {
    setPrefecture((prevState) => ({ ...prevState, value }));
  };

  const handleChangeDropdown = () => {
    // eslint-disable-next-line no-console
    console.log("Dropdown Changed");
  };

  return (
    <div className="admin-area  user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Sheet padding="0 16px" minWidth="1076px" color="white">
          <Tabs bottomBorder selectedTab={3} tabs={TAB_ITEMS} />
        </Sheet>
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="MRI検査"
          content="入会6か月が経過したのでMRI検査を受信できます"
          borderBottom="primary"
        />
        <Sheet minWidth="1076px" padding="24px 16px">
          <LayoutBox width="1064px" direction="column" gap="3x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="検査の前に"
                borderBottom="primaryLight"
                withButton={false}
              />
              <LayoutBox gap="1x" direction="column" fullWidth>
                <Card
                  status="check"
                  content={
                    <>
                      <LayoutBox direction="column" gap="1x">
                        <Text size="xl" bold color="neutralUserBlack">
                          オンライン問診
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          説明テキスト説明テキスト説明テキスト説明テキスト
                        </Text>
                      </LayoutBox>
                    </>
                  }
                />
                <Card
                  status="check"
                  content={
                    <>
                      <LayoutBox direction="column" gap="1x">
                        <Text size="xl" bold color="neutralUserBlack">
                          のうKNOW
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          説明テキスト説明テキスト説明テキスト説明テキスト
                        </Text>
                      </LayoutBox>
                    </>
                  }
                />
              </LayoutBox>
            </LayoutBox>
            <Title
              type="h2"
              header="医療機関の予約"
              borderBottom="primaryLight"
              withButton={false}
            />
            <Sheet type="card">
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox
                  gap="1x"
                  className="util-sp-flex--column util-sp-full-width"
                >
                  <SpFormSet label="都道府県" vertical minWidth="207px">
                    <SpDropdown
                      value={prefecture.value}
                      items={DROPDOWN_PREFECTURES}
                      onChange={handleChangeFilter}
                      width="100%"
                    />
                  </SpFormSet>
                  <SpFormSet label="提携病院" vertical minWidth="207px">
                    <SpDropdown
                      value={DROPDOWN_HOSPITALS[0].value}
                      items={DROPDOWN_HOSPITALS}
                      onChange={handleChangeDropdown}
                      width="100%"
                    />
                  </SpFormSet>
                </LayoutBox>
                <Divider darkColor orange={false} margin="0" />

                {Array.from({ length: candidateCount }, (_, index) => (
                  <LayoutBox direction="column" gap="1x" fullWidth key={index}>
                    <LayoutBox align="center" gap="1x">
                      <Text bold size="xl" color="neutralUserBlack">
                        第{index + 1}候補
                      </Text>
                      <Badge color="red">必須</Badge>
                    </LayoutBox>
                    <LayoutBox
                      gap="1x"
                      className="util-sp-flex--column util-sp-full-width"
                    >
                      <SpFormSet label="日付" vertical minWidth="207px">
                        <SpDropdown
                          value={DROPDOWN_EMPTY[0].value}
                          items={DROPDOWN_EMPTY}
                          width="100%"
                        />
                      </SpFormSet>
                      <SpFormSet label="時間" vertical minWidth="207px">
                        <SpDropdown
                          items={DROPDOWN_EMPTY}
                          onChange={handleChangeDropdown}
                          width="100%"
                        />
                      </SpFormSet>
                    </LayoutBox>
                  </LayoutBox>
                ))}

                <SpButton
                  type="secondary"
                  icon="add"
                  width="200px"
                  onClick={() =>
                    setCandidateCount((prevCount) => prevCount + 1)
                  }
                >
                  候補日を追加
                </SpButton>
                <Divider darkColor orange={false} />
                <SpButton width="311px" withArrowRight size="medium">
                  申込み
                </SpButton>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC008;
