// 共通処理

import {
  JST_OFFSET_HOURS,
  FORMAT_STYLE,
  UserStatusID,
  YOSE_STATUS,
  SALES_CATEGORY,
} from "../constants/common";
import { navigateRefresh } from "../hooks/base/usePageTransitionCustom";
import {
  redirectToInvalidAccessPage,
  redirectToInvalidCsvFormatPage,
  redirectToInvalidFunctionPage,
} from "../contexts/CustomErrorBoundary";

type Checked = {
  checked: boolean;
};

type CsvDataType<T> = {
  headerValue: string; // ヘッダーに表示したい文字列
  valueKey: keyof T; // 表示するデータオブジェクトのkey
};

type Transformer<SourceType, TargetType> = (source: SourceType) => TargetType;

type SelectYearType = {
  value: string;
  label: string;
};

// 表示値からCSVのデータに変換
const _convertDisplayToCsv = <T extends Checked>(
  userInfoArr: T[],
  csvDataArr: CsvDataType<T>[],
): string[][] => {
  // ヘッダー作成
  const headerArr = csvDataArr.map(({ headerValue }) => headerValue);

  // 表示値作成
  const contentArr = userInfoArr
    .map((userInfo) => {
      const { checked } = userInfo;
      // チェックの付いたもののみCSVに変換
      if (checked) {
        return csvDataArr.map(({ valueKey }) => {
          // 空文字の場合‐を表示
          const contentValue = userInfo[valueKey] || "-";

          if (typeof contentValue === "string") {
            // 先頭0始まりの値は式化して0の削除を回避
            if (contentValue.charAt(0) === "0") return `="${contentValue}"`;
            // ,が含まれる場合は文字列化
            if (contentValue.includes(",")) return `"${contentValue}"`;

            return contentValue;
          }

          return redirectToInvalidFunctionPage();
        });
      }

      return [];
    })
    .filter((data) => data.length);

  return [headerArr, ...contentArr];
};

// csvダウンロード処理
const _downloadCsv = (data: string[][], fileName: string) => {
  const csvContent = `\uFEFF${data.map((row) => row.join(",")).join("\n")}`;

  // Blobオブジェクトを作成
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // ダウンロード可能なURLを作成
  const url = URL.createObjectURL(blob);

  // ダウンロードリンクを作成
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${fileName}.csv`;

  // ダウンロードリンクをクリックしてダウンロードを開始
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// 配列オブジェクトを引数の型に沿ってフラットにして返す
const _transformArray = <SourceType, TargetType>(
  arr: SourceType[],
  transformer: Transformer<SourceType, TargetType>,
): TargetType[] => arr.map(transformer);

// csvアップロード用エクスプローラ表示 引数のloadFuncにファイル読み取り時のイベントを返す
export const fileUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  loadFunc: (event: ProgressEvent<FileReader>) => void,
) => {
  const { files } = e.target;
  if (files && files[0]) {
    const reader = new FileReader();

    reader.onload = (event) => {
      loadFunc(event);
    };

    reader.readAsText(files[0]);
  }
};

// ファイルアップロード時のファイルデータをCSVファイル形式に変換
export const convertFileToCsv = (event: ProgressEvent<FileReader>) => {
  const csvStr = event.target?.result;
  if (csvStr && typeof csvStr === "string") {
    return csvStr.split("\n").map((csvDataRowStr) => csvDataRowStr.split(","));
  }
  // csvファイルが不正の場合
  redirectToInvalidCsvFormatPage();

  return "";
};

// タブクリック時の遷移イベント
export const handleSelectTab = (
  selectId: string,
  subTabItems: { id: string; url: string }[],
) => {
  const selectInfo = subTabItems.find(({ id }) => id === selectId);
  navigateRefresh(selectInfo?.url);
};

// クリップボードにテキストをコピー
export const copyClipboard = (text: string) => {
  if (!navigator.clipboard) {
    // eslint-disable-next-line no-alert
    alert("このブラウザは対応していません");

    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-alert
      alert("文章をコピーしました。");
    },
    () => {
      // eslint-disable-next-line no-alert
      alert("コピーに失敗しました。");
    },
  );
};

// URLのパラメータ取得
export const getUrlParams = (target: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(target);
  if (!param) redirectToInvalidAccessPage();

  return param;
};

// Date型からhh:mmの時間を返す
export const formatHourTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

// 全角を半角に変換
export const toHalfWidth = (str: string) =>
  str.replace(/[！-～]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

// 文字列を数値に変換 全角の数値も適用するため半角に変換後数値化
export const convertStrToNum = (str: string) =>
  Number(toHalfWidth(str).replace(/,/g, ""));

// 入力された文字列が数値化をチェック
export const checkStrArrIsNum = (strArr: string[]) =>
  strArr.every((str) => !Number.isNaN(convertStrToNum(str)));

// カンマ区切りに変換
export const convertToCommaSeparatedString = (value: number) =>
  value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// 年のセレクトボックスの値をデータから作成
export const extractUniqueYearsFromData = <T>({
  key,
  data,
}: {
  key: keyof T;
  data: T[];
}): SelectYearType[] => {
  const yearsSet = new Set(
    data
      .filter((item) => item[key])
      .map((item) => {
        const jstDate = new Date(item[key] as unknown as string);
        jstDate.setHours(jstDate.getHours() + JST_OFFSET_HOURS);

        return jstDate.getUTCFullYear().toString();
      }),
  );
  // ソート
  const sortedYears = [...yearsSet].sort((a, b) => parseInt(a) - parseInt(b));

  return sortedYears.map((year) => ({
    value: year,
    label: `${year}年`,
  }));
};

// YYYYMMを+1、-1にしたYYYYMMを返す
export const adjustMonth = (
  yearMonth: string,
  operation: "before" | "after",
): string => {
  let year = parseInt(yearMonth.substring(0, 4), 10);
  let month = parseInt(yearMonth.substring(4, 6), 10);

  switch (operation) {
    case "before":
      month -= 1;
      if (month === 0) {
        year -= 1;
        month = 12;
      }
      break;

    case "after":
      month += 1;
      if (month === 13) {
        year += 1;
        month = 1;
      }
      break;

    default:
      return redirectToInvalidFunctionPage();
  }

  return `${year}${month.toString().padStart(2, "0")}`;
};

// 画像URLをbase64に変換する処理
export const convertUrlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
// 画像URL一覧をbase64に変換する処理
export const convertUrlsToBase64 = async (
  urls: string[],
): Promise<string[]> => {
  const base64Promises = urls.map((url) => convertUrlToBase64(url));

  return Promise.all(base64Promises);
};

// 第2引数に3を入力すると3ヶ月後, -2で２か月前のdateを返します。
export const calcMonth = (date = new Date(), number = 0) => {
  const _date = new Date(date.getTime());
  _date.setMonth(date.getMonth() + number);

  return _date;
};

// 第2引数に3を入力すると3日後, -2で2日前のdateを返します。
export const calcDay = (date = new Date(), number = 0) => {
  const _date = new Date(date.getTime());
  _date.setDate(date.getDate() + number);

  return _date;
};

// filledWith0(23, 3)で023, filledWith0(111, 5)で00111を返します。
export const filledWith0 = (targetNumber: number, digit: number) =>
  `${0 * digit}${targetNumber}`.slice(-digit);

// 日付を指定のフォーマットで返します。デフォルトは、YYYY/MM/DDの形
export const formatDate = (
  date = new Date(),
  selectedFormatStyle: ValueOf<typeof FORMAT_STYLE> | undefined = undefined,
) => {
  const month = filledWith0(date.getMonth() + 1, 2);
  const day = filledWith0(date.getDate(), 2);
  const hours = filledWith0(date.getHours(), 2);
  const minutes = filledWith0(date.getMinutes(), 2);

  if (selectedFormatStyle === FORMAT_STYLE.YYYYMMDD) {
    return `${date.getFullYear()}${month}${day}`;
  }

  if (selectedFormatStyle === FORMAT_STYLE["YYYY/MM/DD HH:MM"]) {
    return `${date.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
  }

  if (selectedFormatStyle === FORMAT_STYLE["HH:MM"]) {
    return `${hours}:${minutes}`;
  }

  if (selectedFormatStyle === FORMAT_STYLE["YYYY-MM-DD"]) {
    return `${date.getFullYear()}-${month}-${day}`;
  }

  if (selectedFormatStyle === FORMAT_STYLE.JPN_YMD) {
    return `${date.getFullYear()}年${month}月${day}日`;
  }

  return `${date.getFullYear()}/${month}/${day}`;
};

export const paymentUrlFormatDate = (date = new Date()) => {
  const get0PaddingNumber = (num: number) => `00${num.toString()}`.slice(-2);

  return `${date.getFullYear()}-${get0PaddingNumber(
    date.getMonth() + 1,
  )}-${get0PaddingNumber(date.getDate())} ${get0PaddingNumber(
    date.getHours(),
  )}:${get0PaddingNumber(date.getMinutes())}:${get0PaddingNumber(
    date.getSeconds(),
  )}`;
};

// 時刻基準で年齢を返します。4月2日生まれの人は、4月2日の0時ちょうどに歳をとります。
export const calcAge = (birthDay: Date, baseDate: Date = new Date()) => {
  const age = Math.floor(
    (Number(formatDate(baseDate, FORMAT_STYLE.YYYYMMDD)) -
      Number(formatDate(birthDay, FORMAT_STYLE.YYYYMMDD))) /
      10000,
  );

  return age;
};

export const getUserStatus = (currentUser: Realm.User | null) => {
  const userStatusObject = currentUser?.customData
    .status as CustomDataNumberType;
  let userStatus = null;
  if (userStatusObject) {
    if ("$numberInt" in userStatusObject) {
      userStatus = userStatusObject
        ? Number(userStatusObject.$numberInt)
        : null;
    } else if ("$numberLong" in userStatusObject) {
      userStatus = userStatusObject
        ? Number(userStatusObject.$numberLong)
        : null;
    }
  }

  return userStatus as UserStatusID;
};

export const getUserPatientType = (currentUser: Realm.User | null) => {
  const userObject = currentUser?.customData;
  if (userObject) {
    const { zipcode, pref, city, town1 } = userObject;
    if (zipcode && pref && city && town1) {
      return true;
    }

    return false;
  }

  return false;
};

// 初回のMRI検査が受診可能か
export const canInitReceiveMri = (currentUser: Realm.User | null) => {
  const userStatusObject = currentUser?.customData
    .activation_date as CustomDateType;
  if (
    userStatusObject &&
    userStatusObject.$date &&
    userStatusObject.$date.$numberLong
  ) {
    const activationDateTime = Number(userStatusObject.$date.$numberLong);
    const activationDate = new Date(activationDateTime);

    const currentDate = new Date();
    const mriElapsedDate = parseInt(
      process.env.REACT_APP_MRI_PET_ONLINE_TEST_START_DAYS || "270",
      10,
    );
    const daysAgo = new Date(
      currentDate.setDate(currentDate.getDate() - mriElapsedDate),
    );

    return activationDate <= daysAgo;
  }

  return false;
};

/**
 *  yoseのステータスを日付から計算して返します。(フロント用)
 *  実装の都合で、queryファイルにおけるgetYoseStatusFieldsにも同様のロジックがあります。
 */
export const getYoseStatus = (data: {
  streamingDate: Date;
  streamingEndDate: Date;
  newPeriod: Date;
  isSuspend: boolean;
}) => {
  const { streamingDate, streamingEndDate, newPeriod, isSuspend } = data;
  const toDay = new Date();

  // 比較する時間を揃える
  toDay.setHours(0, 0, 0, 0);

  if (isSuspend) return YOSE_STATUS.suspend;

  if (streamingEndDate.getTime() < toDay.getTime())
    return YOSE_STATUS.publicClose;

  if (toDay.getTime() < streamingDate.getTime()) return YOSE_STATUS.preDelivery;

  if (newPeriod.getTime() < toDay.getTime()) return YOSE_STATUS.publicOpen;

  return YOSE_STATUS.newPeriod;
};

// 日付が1年前か判定(時刻無視)
export const isOlderThanOneYear = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const comparisonDate = new Date(date);
  comparisonDate.setHours(0, 0, 0, 0);

  return comparisonDate <= oneYearAgo;
};

// 予約日時選択 必須チェック
export const checkBookingRequireRules = (
  selectedDates: Array<Date | undefined>,
  selectedTimes: Array<string | undefined>,
  candidateCount: number,
) => {
  const indicesToCheck = Array.from({ length: candidateCount }, (_, i) => i);

  return indicesToCheck.every(
    (index) =>
      selectedDates[index] !== undefined && selectedTimes[index] !== undefined,
  );
};

// 予約日時選択 任意候補日の日付と時間の一致チェック
export const validateOptionalBookingDatesAndTimes = (
  selectedDates: Array<Date | undefined>,
  selectedTimes: Array<string | undefined>,
): boolean => {
  // selectedDates[3]が存在する場合、selectedTimes[3]も存在する必要がある
  if (selectedDates[3] !== undefined && selectedTimes[3] === undefined) {
    return false;
  }

  // selectedDates[4]が存在する場合、selectedTimes[4]も存在する必要がある
  if (selectedDates[4] !== undefined && selectedTimes[4] === undefined) {
    return false;
  }

  return true;
};

// 予約日時選択 任意候補日の日付と時間チェック
export const hasDuplicateBookings = (
  selectedDates: Array<Date | undefined>,
  selectedTimes: Array<string | undefined>,
): boolean => {
  const combinedBookings = selectedDates.map((date, index) => {
    const dateString = date ? date.toISOString() : "undefined";
    const timeString = selectedTimes[index] ?? "undefined";

    return `${dateString}-${timeString}`;
  });
  const uniqueBookings = new Set(combinedBookings);

  return uniqueBookings.size < combinedBookings.length;
};

export const addHyphenToZipcode = (zipcode: string | undefined) => {
  if (!zipcode || zipcode.length !== 7) {
    return zipcode;
  }

  return `${zipcode.slice(0, 3)}-${zipcode.slice(3)}`;
};

export const generateDays = (year: string, month: string) => {
  const daysInMonth = new Date(Number(year), Number(month), 0).getDate();

  return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
};

export const isActiveUser = (currentUser: Realm.User | null) => {
  const userStatus = getUserStatus(currentUser);

  // 退会のみ。休会は対象外
  return ![UserStatusID.CANCELLATION].includes(userStatus);
};

export const getSalesCategoryDisplay = (
  category: ValueOf<typeof SALES_CATEGORY>,
) => {
  if (category === SALES_CATEGORY.subscription) return "会費";
  if (category === SALES_CATEGORY.interview) return "面談費用";
  if (category === SALES_CATEGORY.ec) return "ショップ";
  if (category === SALES_CATEGORY.pet) return "FDG PET検査費用";
  if (category === SALES_CATEGORY.mri) return "MRI検査費用";

  return undefined;
};

// 税抜き計算
export const calculateExcludingTax = (
  amount: number,
  taxRate: number,
): number => amount / (1 + taxRate / 100);

// 消費税計算
export const calculateTaxAmount = (amount: number, taxRate: number): number => {
  const excludingTax = calculateExcludingTax(amount, taxRate);

  return Math.round(amount - excludingTax);
};

// MRI、PET、オンライン問診が受検期間内かどうか
export const isWithinExaminationPeriod = (
  currentUser: Realm.User | null,
  fixBookDateStart: Date | undefined = undefined,
) => {
  const activationDateObject = currentUser?.customData
    .activation_date as CustomDateType;
  const activationDate = new Date(
    Number(activationDateObject.$date.$numberLong),
  );
  activationDate.setHours(0, 0, 0, 0);

  const startDays = parseInt(
    process.env.REACT_APP_MRI_PET_ONLINE_TEST_START_DAYS || "270",
    10,
  );
  const endDays = parseInt(
    process.env.REACT_APP_MRI_PET_ONLINE_TEST_END_DAYS || "95",
    10,
  );

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };

  const calculateYearDifference = (startDate: Date, endDate: Date) => {
    const diffInMs = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays / 365) + 1;
  };

  const calculateYearRange = (
    n: number,
    startDays: number,
    endDays: number,
    baseDate: Date,
  ) => {
    const startDay = addDays(baseDate, startDays + (n - 1) * 365);
    const endDay = addDays(baseDate, startDays + endDays + (n - 1) * 365);

    return { startDay, endDay };
  };

  const currentDate = new Date();
  const yearDifference = calculateYearDifference(activationDate, currentDate);
  const range = calculateYearRange(
    yearDifference,
    startDays,
    endDays,
    activationDate,
  );

  return fixBookDateStart
    ? fixBookDateStart >= range.startDay && fixBookDateStart <= range.endDay
    : currentDate >= range.startDay && currentDate <= range.endDay;
};

// アクティベート日付から何年目のユーザーか
export const getUserYearSinceActivation = (currentUser: Realm.User | null) => {
  const activationDateObject = currentUser?.customData
    .activation_date as CustomDateType;
  const activationDate = new Date(
    Number(activationDateObject.$date.$numberLong),
  );
  activationDate.setHours(0, 0, 0, 0);

  const calculateYearDifference = (startDate: Date, endDate: Date) => {
    const diffInMs = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays / 365) + 1;
  };

  const currentDate = new Date();
  const yearDifference = calculateYearDifference(activationDate, currentDate);

  return yearDifference;
};

// 次回のMRI、PETの受検開始日
export const getNextExaminationStartDate = (
  currentUser: Realm.User | null,
  userYearSinceActivation: number,
) => {
  const activationDateObject = currentUser?.customData
    .activation_date as CustomDateType;
  const activationDate = new Date(
    Number(activationDateObject.$date.$numberLong),
  );
  activationDate.setHours(0, 0, 0, 0);

  const startDays = parseInt(
    process.env.REACT_APP_MRI_PET_ONLINE_TEST_START_DAYS || "270",
    10,
  );

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };

  const calculateYearRange = (n: number, startDays: number, baseDate: Date) => {
    const startDay = addDays(baseDate, startDays + (n - 1) * 365);

    return startDay;
  };

  const startDay = calculateYearRange(
    userYearSinceActivation + 1,
    startDays,
    activationDate,
  );

  return startDay;
};
