import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Gift from "../assets/gift_box.png";
import WomanWithPhone from "../assets/woman_with_phone.png";
import SpButton from "../components/Button/SpButton";
import usePatientInvitationsFetch from "../hooks/common/usePatientInvitationsFetch";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import useUsa029Actions from "../hooks/pages/USA029/useUsa029DBActions";
import PatientFooter from "../features/PatientFooter";
import { PatientTypeID } from "../constants/common";
import { setPath } from "../hooks/common/usePath";
import { setId } from "../hooks/common/useId";
import NotificationCard from "../features/NotificationCard";
import InvitationUserCard from "../features/InvitationUserCard";

const USA029 = () => {
  const { patientInvitations } = usePatientInvitationsFetch("all");
  const { result } = useUsa029Actions();
  const navigate = useNavigate();

  // 画面遷移
  const onAccountCreateButtonClicked = () => {
    setPath(INVITATION.USA029);
    navigate(PATIENT.USA004);
  };
  const onAccountListButtonClicked = () => navigate(INVITATION.USA030);
  const onAccountButtonClicked = (id: string, status: number) => {
    setId(id);
    setPath(INVITATION.USA029);
    const route = status === 1 ? INVITATION.USA032 : INVITATION.USA031;
    navigate(route);
  };

  // 登録済と登録前のアカウントで分ける
  const registered: PatientInvitation[] = [];
  const unregistered: PatientInvitation[] = [];
  patientInvitations.forEach((data) => {
    if (data.name) {
      registered.push(data);
    } else {
      unregistered.push(data);
    }
  });

  // 申請中のユーザーがいるかどうか判定
  const hasSpecificValue = registered.filter((item) => item.status === 1);

  return (
    <div className="admin-area  user-body-sp-full-height">
      <HeaderUser backgroundColor="rough-yellow" />
      <div className="admin-inner area-with-header-footer">
        <Sheet minWidth="1076px" padding="16px" color="rough-yellow">
          <LayoutBox width="1064px" direction="column" gap="2x">
            <LayoutBox direction="column" gap="2x" fullWidth>
              {hasSpecificValue.length !== 0 && (
                <Card
                  onClick={() =>
                    onAccountButtonClicked(
                      hasSpecificValue[0].invited_patient_id,
                      hasSpecificValue[0].status,
                    )
                  }
                  status="warning"
                  header={
                    <Text bold size="xl">
                      招待コードが利用されました
                    </Text>
                  }
                  content={
                    <Text size="large">
                      ご自身が招待された方であることを確認して承認してください
                    </Text>
                  }
                />
              )}
              <LayoutBox direction="column" gap="1x">
                <LayoutBox gap="1x" className="util-mb-4">
                  <img src={Gift} alt="megaphone" />
                  <div className="util-mt-24">
                    <Text
                      size="2xl"
                      bold
                      yellowUnderline
                      lineHeight="50%"
                      titleFont
                    >
                      招待したアカウント
                    </Text>
                  </div>
                </LayoutBox>
                <Text size="xl" titleFont>
                  招待コードを発行してプレゼントしたアカウントを確認・管理できます。
                </Text>
              </LayoutBox>

              {/* 招待したアカウント */}
              <InvitationUserCard
                registered={registered}
                unregistered={unregistered}
              />

              <LayoutBox fullWidth justify="center">
                <SpButton
                  withArrowRight
                  width="343px"
                  onClick={onAccountListButtonClicked}
                >
                  <Text color="white" titleFont size="xl" semibold>
                    招待したアカウントを管理する
                  </Text>
                </SpButton>
              </LayoutBox>
            </LayoutBox>
            <div />
          </LayoutBox>
        </Sheet>
        <Sheet
          relative
          color="rough-yellow-wavy"
          padding="12px"
          minWidth="1076px"
        >
          <div />
        </Sheet>

        <div className="util-mb-40" />
        <div className="util-mb-32" />
        {result?.patient_type === PatientTypeID.INVITATION && (
          <Sheet
            minWidth="1076px"
            padding="42px 0 0"
            relative
            spPadding={false}
          >
            <img src={WomanWithPhone} alt="gift" className="image--enjoy" />
            <LayoutBox fullWidth>
              <Sheet
                color="rough-orange"
                padding="50px 16px 24px"
                minWidth="375px"
              >
                <LayoutBox
                  justify="center"
                  align="center"
                  gap="2x"
                  direction="column"
                  fullWidth
                >
                  <Text
                    size="2xl"
                    bold
                    color="white"
                    yellowUnderline
                    lineHeight="80%"
                  >
                    サービスを始めてみる
                  </Text>
                  <Text size="xl" color="white" textAlign="center">
                    招待するためにアカウント登録された方も、
                    「認知症と向き合う365」をご利用いただけます。
                  </Text>
                  <SpButton
                    type="white"
                    withArrowRight
                    width="343px"
                    size="medium"
                    onClick={onAccountCreateButtonClicked}
                  >
                    サービスを利用する
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        )}
        {result?.patient_type === PatientTypeID.INVITATION && (
          <Sheet padding="40px 108px" minWidth="1076px">
            <LayoutBox>
              {/* お知らせ */}
              <NotificationCard />
            </LayoutBox>
          </Sheet>
        )}
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA029;
