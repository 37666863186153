import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpDropdown from "../components/Dropdown/SpDropdown";
import Card from "../components/Card/Card";
import SpButton from "../components/Button/SpButton";
import { INVITATION } from "../constants/pagePaths";
import useUsa031Actions from "../hooks/pages/USA031/useUsa031DBActions";
import useUsa031Conditions from "../hooks/pages/USA031/useUsa031Conditions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { convertToCommaSeparatedString } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";
import Divider from "../components/Divider/Divider";
import {
  MriStatusID,
  PATIENT_INVITATIONS_STATUS,
  PatientBrainCheckStatusID,
  PatientNouKnowStatusID,
  PetStatusID,
  PlanSpanID,
  USC_TAB_ITEMS,
} from "../constants/common";
import InformationDialog from "../components/Dialog/InformationDialog";
import useStripeActions from "../hooks/common/useStripeActions";
import useUsk002DBFetch from "../hooks/pages/USK002/useUsk002DBFetch";
import usePath from "../hooks/common/usePath";
import { setCancelEmailConditions } from "../hooks/common/useCancelEmailConditions";
import PaymentFailureCard from "../features/PaymentFailureCard";

const DROPDOWN_FILTER = [
  { value: "All", label: "すべて" },
  { value: "1", label: "結果待ち" },
  { value: "2", label: "レポート受信済み" },
];

const USA031 = () => {
  const [conditions] = useUsa031Conditions();
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [selectedTab, setSelectedTab] = useState(1);
  const {
    result,
    error,
    selectOrderYear,
    listResult,
    mriListResult,
    petListResult,
    fetchError,
    updateUser,
  } = useUsa031Actions({
    orderYear: searchCriteria.orderYear,
  });
  const navigate = useNavigateWithUrl();
  const stateNavigate = useNavigate();
  const [path] = usePath();
  const [isOpen, setIsOpen] = React.useState(false);
  const [cancelEmail, setCancelEmail] = React.useState("");
  const { fetchStripeCardUpdateLink, stripeError } = useStripeActions();
  const [selectedBrainCheck, setSelectedBrainCheck] = React.useState("All");
  const { fetchResult: brainCheckResult, fetchError: fetchBrainCheckError } =
    useUsk002DBFetch(selectedBrainCheck);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");

  // エラー処理
  useCheckErrorThrowError([
    error,
    fetchError,
    stripeError,
    fetchBrainCheckError,
  ]);

  // 注文日 年
  const handleChangeYear = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, orderYear: value }));
  };

  // タブの切り替え
  const handleTabChange = (index: string | number) => {
    setSelectedId(index);
    setSelectedTab(typeof index === "string" ? parseInt(index, 10) : index);
  };

  // 前画面に戻る
  const handleClickBack = () => {
    navigate(path);
  };

  // 退会ボタン
  const handleCancellation = () => {
    void (async () => {
      const isSuccess = await updateUser(cancelEmail);
      if (!isSuccess) return;
      setIsOpen(false);

      setCancelEmailConditions(cancelEmail);
      stateNavigate(INVITATION.USA039);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  // 退会ダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // 退会ダイアログopen
  const handleCancelConfirm = (email = "") => {
    setCancelEmail(email);
    setIsOpen(true);
  };

  const handleEditCreditCard = async () => {
    const stripeSubscriptionId = result?.stripeSubscriptionId;
    if (stripeSubscriptionId) {
      const stripeUrl = await fetchStripeCardUpdateLink(
        stripeSubscriptionId,
        INVITATION.USA031,
      );
      if (stripeUrl) {
        window.location.href = stripeUrl;
      }
    }
  };

  // 頭の健康チェックセレクトボックス 結果で絞り込む
  const handleChangeFilter = (value: string) => {
    setSelectedBrainCheck(value);
  };

  // のうKNOW
  const nouKnow = () =>
    listResult[0] ? (
      listResult.map((data, index) => (
        <Card
          status={
            data.status === PatientNouKnowStatusID.COMMENTED
              ? "description"
              : "pending"
          }
          clickable={false}
          key={index}
          content={
            <>
              <LayoutBox direction="column" gap="1x">
                <Text size="large" color="neutralLight">
                  受検日：{data.finished_at}
                </Text>
                <Text size="xl" bold>
                  のうKNOW
                </Text>
              </LayoutBox>
            </>
          }
        />
      ))
    ) : (
      <LayoutBox justify="center" align="center" fullWidth>
        <Text size="large" color="neutralLight">
          表示する結果がありません
        </Text>
      </LayoutBox>
    );

  // MRI検査
  const mri = () =>
    mriListResult[0] ? (
      mriListResult.map((data, index) => (
        <Card
          clickable={false}
          key={index}
          status={
            data.mri_status === MriStatusID.USER_REPORTED
              ? "description"
              : "pending"
          }
          header={
            <Text size="large" color="neutralUserLight">
              受検日：{data?.fix_book_date_start}
            </Text>
          }
          content={
            <Text bold size="xl">
              MRI検査
            </Text>
          }
        />
      ))
    ) : (
      <LayoutBox justify="center" align="center" fullWidth>
        <Text size="large" color="neutralLight">
          表示する結果がありません
        </Text>
      </LayoutBox>
    );

  // PET検査
  const pet = () =>
    petListResult[0] ? (
      petListResult.map((data, index) => (
        <Card
          clickable={false}
          key={index}
          status={
            data.pet_status === PetStatusID.USER_REPORTED
              ? "description"
              : "pending"
          }
          header={
            <Text size="large" color="neutralUserLight">
              受検日：{data?.fix_book_date_start}
            </Text>
          }
          content={
            <Text bold size="xl">
              FGD PET検査
            </Text>
          }
        />
      ))
    ) : (
      <LayoutBox justify="center" align="center" fullWidth>
        <Text size="large" color="neutralLight">
          表示する結果がありません
        </Text>
      </LayoutBox>
    );

  // あたまの健康チェック
  const brainCheck = () =>
    brainCheckResult[0] ? (
      brainCheckResult.map((result) => (
        <Card
          key={result._id}
          status={
            result.status === PatientBrainCheckStatusID.COMPLETED
              ? "pending"
              : "description"
          }
          header={
            <Text size="large" color="neutralUserLight">
              受検案内日: {result.examStartDate}
            </Text>
          }
          content={
            <Text bold size="xl">
              あたまの健康チェック
            </Text>
          }
          clickable={false}
        />
      ))
    ) : (
      <LayoutBox justify="center" align="center" fullWidth>
        <Text size="large" color="neutralLight">
          表示する結果がありません
        </Text>
      </LayoutBox>
    );

  return (
    <div className="admin-area user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header={result?.name}
          content={result?.email}
          borderBottom="primary"
          minWidth="1078px"
          onClickBack={handleClickBack}
        />
        <Sheet padding="40px 0" className="util-sp-py-24" minWidth="1076px">
          <LayoutBox direction="column" width="1064px" gap="3x">
            {/* カード決済失敗時のカード */}
            {result?.status === PATIENT_INVITATIONS_STATUS.paymentError && (
              <PaymentFailureCard
                stripeSubscriptionId={result?.stripeSubscriptionId}
                backUrl={INVITATION.USA031}
              />
            )}
            <Title
              withButton={false}
              header="検査状況"
              borderBottom="primaryLight"
              type="h2"
            />
            <div className="util-flex util-flex-col util-sp-flex--column util-flex--gap8 util-full-width">
              <ToggleButton
                selectedButton={selectedButton}
                size="large"
                width="100%"
                items={USC_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
              />
              <ToggleButton
                selectedButton={selectedButton}
                size="large"
                width="100%"
                items={USC_TAB_ITEMS.slice(2, 4)}
                onClick={handleTabChange}
              />
            </div>
            {selectedTab === 1 && (
              <SpFormSet label="受検した年で絞り込む" vertical>
                <SpDropdown
                  items={selectOrderYear}
                  value={
                    searchCriteria.orderYear
                      ? searchCriteria.orderYear
                      : "すべて"
                  }
                  onChange={handleChangeYear}
                  width="195px"
                />
              </SpFormSet>
            )}
            {selectedTab === 4 && (
              <SpFormSet label="結果で絞り込む" vertical>
                <SpDropdown
                  value={selectedBrainCheck}
                  items={DROPDOWN_FILTER}
                  onChange={handleChangeFilter}
                  width="195px"
                />
              </SpFormSet>
            )}
            <LayoutBox direction="column" gap="1x" fullWidth>
              <Text size="xl">検査結果は受検者にのみ開示されます。</Text>
              {selectedTab === 1 && nouKnow()}
              {selectedTab === 2 && mri()}
              {selectedTab === 3 && pet()}
              {selectedTab === 4 && brainCheck()}
            </LayoutBox>

            <Divider margin="0" darkColor />
            <Sheet type="card" padding="16px 16px 32px">
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox direction="column" fullWidth>
                  <Text size="2xl" bold color="neutralUserBlack">
                    お支払い情報
                  </Text>
                  <Divider borderWidth="3px" margin="0" />
                </LayoutBox>
                <LayoutBox direction="column" gap="3x" fullWidth>
                  <SpFormSet
                    label="カード情報"
                    labelWidth="200px"
                    base
                    spToColumnAndFullWidth
                  >
                    <SpButton
                      size="small"
                      width="311px"
                      withArrowRight
                      onClick={() => {
                        void handleEditCreditCard();
                      }}
                    >
                      カード情報を編集
                    </SpButton>
                  </SpFormSet>
                  <SpFormSet
                    label="お支払いコース"
                    labelWidth="200px"
                    base
                    spToColumnAndFullWidth
                  >
                    <LayoutBox direction="column" gap="1x" fullWidth>
                      <Text size="xl" color="neutralUserBlack">
                        {result?.subscriptionSpan === PlanSpanID.YEAR
                          ? "年払いコース"
                          : "月払いコース"}
                        :
                        {result?.subscriptionPrice
                          ? convertToCommaSeparatedString(
                              result?.subscriptionPrice,
                            )
                          : ""}
                        {result?.subscriptionSpan === PlanSpanID.YEAR
                          ? "/年"
                          : "/月"}
                      </Text>
                      <Divider margin="0" darkColor />
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="次回更新日"
                    labelWidth="200px"
                    base
                    spToColumnAndFullWidth
                  >
                    <LayoutBox direction="column" gap="1x" fullWidth>
                      <Text size="xl" color="neutralUserBlack">
                        {result?.nextBillingDateStr}
                      </Text>
                      <Divider margin="0" darkColor />
                    </LayoutBox>
                  </SpFormSet>
                </LayoutBox>
                {/* 仕様確認のため、一旦非表示 */}
                {/* <SpButton size="small" width="311px" withArrowRight>
                  お支払い方法を編集
                </SpButton> */}
              </LayoutBox>
            </Sheet>

            <LayoutBox className="util-py-16 util-mb-40">
              <SpButton
                type="danger"
                size="medium"
                width="343px"
                onClick={() => handleCancelConfirm(result?.email)}
              >
                退会させる
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div />
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="退会"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" direction="column" gap="2x">
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
                <SpButton
                  type="danger"
                  width="275px"
                  onClick={handleCancellation}
                >
                  退会させる
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">
                {result?.name}さんを本当に退会させますか？
                <br />
                これまで受けたテストや検査の結果は確認することができますが、各種サービスをご利用いただくには、再度会員登録が必要になります。
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};
export default USA031;
