import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";

export type ImageProps = {
  url?: string;
  alt?: string;
  width?: string;
  height?: string;
  type?: "simple" | "avatar" | "picture" | "imageUpload" | "framed";
  onClick?: () => void | ((index: number) => void);
  className?: string;
  justify?: "start" | "center" | "end" | "between" | "around";
  borderRadius?: string;
  fullWidth?: boolean;
};

const Image = ({
  url = "",
  alt = "",
  width = "85px",
  height = "48px",
  type = "simple",
  onClick,
  className,
  justify = "start",
  borderRadius = "",
  fullWidth = true,
}: ImageProps) => {
  const imageClass = className ? `image ${className}` : "image";
  if (url === "" && type === "simple") {
    return (
      <div
        className="image--placeholder"
        style={{ width, height, borderRadius }}
      />
    );
  }
  if (url === "" && type === "avatar") {
    return (
      <div className="image--avatar">
        <Icon
          icon="person"
          className="icon--avatar"
          size="xl"
          color="#B4BBC6"
        />
      </div>
    );
  }
  if (url === "" && type === "picture") {
    return (
      <div className="image--picture">
        <Icon
          icon="image"
          className="icon--picture"
          size="xl"
          color="#B4BBC6"
        />
      </div>
    );
  }

  if (url && type === "imageUpload") {
    return (
      <div
        className="image-upload--image"
        style={{
          backgroundImage: `url(${url})`,
          width,
          height,
        }}
      >
        <LayoutBox fullWidth align="end" justify="end">
          <Button
            size="small"
            color="neutral"
            type="sub"
            onClick={onClick} // changeEmp
            icon="close"
          />
        </LayoutBox>
      </div>
    );
  }

  if (url && type === "framed") {
    return (
      <div className="image--framed" style={{ height, width }}>
        <img className={imageClass} src={url} alt={alt} />
      </div>
    );
  }

  return (
    <LayoutBox justify={justify} fullWidth={fullWidth}>
      <div onClick={onClick}>
        <img
          className={imageClass}
          src={url}
          alt={alt}
          height={height}
          width={width}
        />
      </div>
    </LayoutBox>
  );
};

Image.defaultProps = {
  url: "",
  alt: "",
  height: "48px",
  width: "85px",
  type: "simple",
  onClick: () => {},
  className: "",
  justify: "start",
  borderRadius: "",
  fullWidth: true,
};

export default Image;
