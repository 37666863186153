import React from "react";
import Icon from "../Icon/Icon";
import SpButton from "../Button/SpButton";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";

export type CardProps = {
  type?: "default" | "button";
  disabled?: boolean;
  // changeEmp
  status?:
    | "none"
    | "check"
    | "warning"
    | "error"
    | "completion"
    | "pending"
    | "description"
    | "waiting"
    | "noExamination";
  header?: JSX.Element;
  content?: JSX.Element;
  buttonType?:
    | "primary"
    | "secondary"
    | "white"
    | "danger"
    | "shop"
    | undefined;
  buttonItem?: {
    label?: string;
    icon?: string;
    width?: string;
    size?: "small" | "medium" | "large" | "2xs" | "xs" | "xl" | "2xl";
    disabled?: boolean;
  };
  footerLabel?: string;
  footerText?: string;
  footerColor?: "blue" | "gray";
  clickable?: boolean;
  onClick?: () => void;
  onButtonItemClick?: () => void;
  minHeight?: "mh-50px" | "mh-80px" | "mh-85px";
};

const Card = ({
  header,
  content,
  type = "default",
  disabled = false,
  status = "none",
  buttonItem,
  buttonType = "primary",
  footerLabel,
  footerText,
  footerColor = "blue",
  onButtonItemClick,
  clickable = true,
  onClick,
  minHeight,
}: CardProps) => {
  const cardClass = React.useMemo(() => {
    const typeClass = type !== "default" ? [`card--${type}`] : [];
    const disabledClass = disabled ? ["card--disabled"] : [];
    const clickableClass =
      clickable || status === "completion" ? ["card--clickable"] : [];

    return ["card", ...typeClass, ...disabledClass, ...clickableClass].join(
      " ",
    );
  }, [type, disabled, clickable, status]);

  const showFooter = footerLabel !== undefined || footerText !== undefined;

  return (
    <>
      <div
        className={cardClass}
        onClick={clickable || status === "completion" ? onClick : undefined}
      >
        <div className="card-top">
          <div className="card-text-area">
            {status !== "none" && (
              <div className="card-status">
                {status === "warning" ? (
                  <>
                    <Icon icon={status} color="#D6A000" size="large" />
                    <span className="card__icon-text--warning">注意</span>
                  </>
                ) : status === "check" ? (
                  <>
                    <Icon icon={status} color="#81C784" size="large" />
                    <span className="card__icon-text--success">問題なし</span>
                  </>
                ) : status === "error" ? (
                  <>
                    <Icon icon={status} color="#E78D87" size="large" />
                    <span className="card__icon-text--error">問題あり</span>
                  </>
                ) : status === "pending" ? (
                  <>
                    <Icon icon={status} color="#ACAAA4" size="large" />
                    <span className="card__icon-text--pending">結果待ち</span>
                  </>
                ) : status === "waiting" ? (
                  <>
                    <Icon icon="local_hospital" color="#ACAAA4" size="large" />
                    <span className="card__icon-text--waiting">検査待ち</span>
                  </>
                ) : status === "noExamination" ? (
                  <>
                    <Icon icon="block" color="#ACAAA4" size="large" />
                    <span className="card__icon-text--no-examination">
                      受検なし
                    </span>
                  </>
                ) : status === "description" ? (
                  <>
                    <Icon icon={status} color="#E79376" size="large" />
                    <span className="card__icon-text--pending">
                      レポート 受信済み
                    </span>
                  </>
                ) : status === "completion" ? (
                  <>
                    <Icon icon="check" color="#81C784" size="large" />
                    <span className="card__icon-text--success">完了</span>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="card-container">
              {header && <div className="card-header">{header}</div>}
              {content && (
                <div
                  className={
                    minHeight !== undefined
                      ? `card-content-${minHeight}`
                      : "card-content"
                  }
                >
                  {content}
                </div>
              )}
            </div>
          </div>
          {type === "default" && clickable ? (
            <div className="card-arrow">
              <Icon type="outlined" icon="expand_circle_right" size="medium" />
            </div>
          ) : type === "button" && buttonItem !== undefined ? (
            <LayoutBox justify="center">
              <SpButton
                type={buttonType}
                icon={buttonItem.icon}
                width={buttonItem.width}
                onClick={onButtonItemClick}
                disabled={buttonItem.disabled}
              >
                {buttonItem.label}
              </SpButton>
            </LayoutBox>
          ) : (
            ""
          )}
        </div>
        {showFooter && (
          <div className={`card-footer card-footer--${footerColor}`}>
            <LayoutBox justify="between" align="center">
              <Text bold size="large">
                {footerLabel}
              </Text>
              <Text size="base">{footerText}</Text>
            </LayoutBox>
          </div>
        )}
      </div>
    </>
  );
};

Card.defaultProps = {
  type: "default",
  disabled: false,
  status: "none",
  header: undefined,
  content: undefined,
  buttonItem: undefined,
  footerLabel: undefined,
  footerText: undefined,
  footerColor: "blue",
  buttonType: "primary",
  onButtonItemClick: undefined,
  clickable: true,
  onClick: undefined,
  minHeight: undefined,
};

export default Card;
