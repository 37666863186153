import { useCallback } from "react";
import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";

const ConsultationDialCard = () => {
  const navigate = useNavigateWithUrl();

  const handleClickCard = useCallback(
    () => navigate(PATIENT.USD009),
    [navigate],
  );

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="center"
            align="start"
            flexShrink={0}
            direction="column"
          >
            <Text bold size="xl">
              認知症相談ダイアル24
            </Text>
            <Text size="large">24時間電話相談</Text>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          会員ご本人様やご家族様からの相談を、医師や保健師・看護師が24時間365日対応いたします。
        </Text>
      }
      onClick={handleClickCard}
    />
  );
};
export default ConsultationDialCard;
