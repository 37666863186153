import Sheet from "../Sheet/Sheet";
import Tabs from "../Tab/Tabs";
import House from "../../assets/tabs-icons/house-icon.svg";
import Notebook from "../../assets/tabs-icons/notebook-icon.svg";
import ChatBubble from "../../assets/tabs-icons/chat-bubble-icon.svg";
import Vaudeville from "../../assets/tabs-icons/vaudeville-icon.svg";
import Cart from "../../assets/tabs-icons/cart-icon.svg";
import useCartInfoFetch from "../../hooks/common/useCartInfoFetch";
import useMenuNavigationFetch from "../../hooks/component/MenuNavigation/useMenuNavigationFetch";
import { navigateRefresh } from "../../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../../constants/pagePaths";
import { useCheckErrorThrowError } from "../../utils/checkError";

export type HeaderProps = {
  leftControl?: JSX.Element;
  content?: JSX.Element;
  rightControl?: JSX.Element;
  backgroundColor?: string;
  isActiveUser?: boolean;
};

export const SELECTED_TAB_NUM = {
  top: 0,
  examination: 1,
  healthAdvice: 2,
  yose: 3,
  shop: 4,
} as const;

const MenuNavigation = (props: {
  color?: "white" | "rough-yellow";
  selectedTab?: ValueOf<typeof SELECTED_TAB_NUM> | undefined;
  padding?: string | undefined;
  minWidth?: string | undefined;
  isActiveUser?: boolean | undefined;
}) => {
  const { color, selectedTab, padding, minWidth, isActiveUser } = props;

  const { isNewYose, isInspectionResult, consultingChatCounts, fetchError } =
    useMenuNavigationFetch();
  const { cartInfo } = useCartInfoFetch();

  const tabItems = isActiveUser
    ? [
        {
          title: "トップ",
          image: House,
          onClick: () => navigateRefresh(PATIENT.USB001),
        },
        {
          title: "検査",
          image: Notebook,
          badge: isInspectionResult ? "結果" : undefined,
          onClick: () => navigateRefresh(PATIENT.USC001),
        },
        {
          title: "健康相談",
          image: ChatBubble,
          badge: consultingChatCounts
            ? String(consultingChatCounts)
            : undefined,
          onClick: () => navigateRefresh(PATIENT.USD004),
        },
        {
          title: "オリジナル落語動画",
          titleMobile: "落語動画",
          image: Vaudeville,
          badge: isNewYose ? "新着" : undefined,
          onClick: () => navigateRefresh(PATIENT.USE002),
        },
        {
          title: "ショップ",
          image: Cart,
          badge: cartInfo?.item_num ? String(cartInfo?.item_num) : undefined,
          onClick: () => navigateRefresh(PATIENT.USF001),
        },
      ]
    : [
        {
          title: "トップ",
          image: House,
          onClick: () => navigateRefresh(PATIENT.USB001),
        },
        {
          title: "検査",
          image: Notebook,
          badge: isInspectionResult ? "結果" : undefined,
          onClick: () => navigateRefresh(PATIENT.USC001),
        },
      ];

  // エラー処理
  useCheckErrorThrowError([fetchError]);

  return (
    <Sheet padding={padding} minWidth={minWidth} color={color}>
      <Tabs bottomBorder selectedTab={selectedTab} tabs={tabItems} />
    </Sheet>
  );
};

MenuNavigation.defaultProps = {
  color: "white",
  selectedTab: undefined,
  padding: "0",
  minWidth: "1076px",
  isActiveUser: true,
};

export default MenuNavigation;
