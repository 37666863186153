import { useEffect } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import SpButtonAccount from "../components/Button/SpButtonAccount";
import PatientFooter from "../features/PatientFooter";
import useCreateAccountInput, {
  checkCreateAccountInputError,
  setEmail,
  setPassword,
} from "../hooks/input/useCreateAccountInput";
import useUsa002DbActions from "../hooks/pages/USA002/useUsa002DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useRealmApp } from "../contexts/RealmApp";
import Gift from "../assets/gift_box.png";

const USA002 = () => {
  const realmAppContext = useRealmApp();
  const [{ email, password }] = useCreateAccountInput();
  const { actionError, handleEmailRegister } = useUsa002DbActions();
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  let presentEmail = searchParams.get("email");

  if (presentEmail) {
    // メールアドレスに含まれる 半角スペース を + に戻す
    presentEmail = presentEmail.replace(" ", "+");
    // メールアドレスをデコードする
    presentEmail = decodeURIComponent(presentEmail);
  } else {
    presentEmail = "";
  }

  useCheckErrorThrowError([actionError]);

  // 送信ボタン押下
  const handleEmailRegisterButton = () => {
    handleEmailRegister(email.value, password.value);
  };

  // LINE
  const handleLine = () => {
    realmAppContext.providerSignIn("line");
  };

  // Google
  const handleGoogle = () => {
    realmAppContext.providerSignIn("google");
  };

  useEffect(() => {
    if (presentEmail) {
      setEmail(presentEmail || "");
    }
  }, [email.value, presentEmail]);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="アカウント作成"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="440px">
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <LayoutBox direction="column" gap="1x" justify="center">
                    <LayoutBox
                      align="center"
                      gap="1x"
                      direction="column"
                      fullWidth
                    >
                      {code && presentEmail && (
                        <img src={Gift} alt="megaphone" />
                      )}
                      {code && presentEmail && (
                        <Text bold size="2xl">
                          招待メールが届きました
                        </Text>
                      )}
                    </LayoutBox>
                    {code && presentEmail && <Divider darkColor />}
                    <LayoutBox direction="column" gap="2x">
                      {code && presentEmail ? (
                        <Text size="xl">
                          パスワードを設定し「送信」ボタンをクリックしてください。
                        </Text>
                      ) : (
                        <Text size="xl">
                          メールアドレスとパスワードを設定し「送信」ボタンをクリックしてください。
                        </Text>
                      )}
                      <LayoutBox gap="1x" direction="column" fullWidth>
                        <Text size="xl" bold color="neutralUserBlack">
                          メールアドレス
                        </Text>
                        <Input
                          width="100%"
                          value={presentEmail || email.value}
                          onChange={(value) => setEmail(value)}
                          error={email.isError}
                          disabled={!!presentEmail}
                        />
                        {[email.validationMessage].map((errorText, index) => (
                          <div
                            key={`caption-massage-${index}`}
                            className="sp-form-set__caption-massage--error"
                          >
                            {errorText}
                          </div>
                        ))}
                      </LayoutBox>
                      <LayoutBox gap="1x" direction="column" fullWidth>
                        <Text size="xl" bold color="neutralUserBlack">
                          パスワード
                        </Text>
                        <Input
                          password
                          leftIcon="lock"
                          rightButton="password"
                          width="100%"
                          value={password.value}
                          onChange={(value) => setPassword(value)}
                          error={password.isError}
                        />
                        {[password.validationMessage].map(
                          (errorText, index) => (
                            <div
                              key={`caption-massage-${index}`}
                              className="sp-form-set__caption-massage--error"
                            >
                              {errorText}
                            </div>
                          ),
                        )}
                      </LayoutBox>
                      <SpButton
                        width="100%"
                        size="medium"
                        buttonType="submit"
                        disabled={checkCreateAccountInputError()}
                        onClick={handleEmailRegisterButton}
                      >
                        送信
                      </SpButton>
                    </LayoutBox>
                  </LayoutBox>
                  {!code && !presentEmail && (
                    <LayoutBox gap="10px" fullWidth align="center">
                      <Divider orange={false} darkColor />
                      <Text size="large" noWrap color="normal">
                        または
                      </Text>
                      <Divider orange={false} darkColor />
                    </LayoutBox>
                  )}
                  {!code && !presentEmail && (
                    <LayoutBox gap="2x" direction="column" fullWidth>
                      <Text
                        textAlign="center"
                        size="xl"
                        bold
                        color="neutralUserBlack"
                      >
                        外部アカウントと連携してアカウントを作成する方はこちら
                      </Text>
                      <SpButtonAccount
                        type="line"
                        width="100%"
                        onClick={handleLine}
                      >
                        LINEアカウントで作成
                      </SpButtonAccount>
                      <SpButtonAccount
                        type="google"
                        width="100%"
                        onClick={handleGoogle}
                      >
                        Googleアカウントで作成
                      </SpButtonAccount>
                    </LayoutBox>
                  )}
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
          <div />
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};
export default USA002;
