import { useEffect } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import StepBar from "../components/StepBar/StepBar";
import Icon from "../components/Icon/Icon";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import {
  useCurrentLocationState,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import {
  PAYMENT_PERIOD,
  REGISTER_STEP_ITEMS,
  SERVICE_CONTENT_CONSULTATION_ITEMS,
  SERVICE_CONTENT_DETAILED_INSPECTION_ITEMS,
  SERVICE_CONTENT_INSPECTION_ITEMS,
} from "../constants/common";
import { checkUserRegisterInputError } from "../hooks/input/useUserRegisterInput";
import useUSA024DbActions from "../hooks/pages/USA024/useUSA024DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { redirectToInvalidAccessPage } from "../contexts/CustomErrorBoundary";
import SpButton from "../components/Button/SpButton";
import PatientFooter from "../features/PatientFooter";
import { convertToCommaSeparatedString } from "../utils/utils";
import BulletList from "../components/List/BulletList";
import usePaymentPeriodId, {
  setPaymentPeriodId,
} from "../hooks/common/usePaymentPeriod";

const getRadioButtonItem = (
  plans: SubscriptionPlansType<typeof PAYMENT_PERIOD>[],
  campaign: USA024Type | undefined,
) => {
  if (campaign) {
    const updatedPlans = plans
      .filter((plan) => plan.span === campaign.payment_span)
      .map((plan) => {
        let updatePrice = null;
        if (campaign.discount_price) {
          updatePrice = plan.price - campaign.discount_price;
        } else if (campaign.discount_rate) {
          updatePrice = Math.round(
            plan.price * (1 - campaign.discount_rate / 100),
          );
        } else {
          updatePrice = plan.price;
        }

        return {
          ...plan,
          newPrice: updatePrice < 0 ? 0 : updatePrice,
        };
      });

    return updatedPlans.map((plan) => ({
      label: (
        <LayoutBox gap="1x" direction="column" fullWidth>
          <Text size="xl" bold>
            {plan?.span === PAYMENT_PERIOD.monthlyPayment
              ? "月払いコース"
              : "年払いコース"}
          </Text>
          <LayoutBox gap="1/2x" fullWidth>
            <Text size="2xl" color="orange" bold>
              {convertToCommaSeparatedString(plan?.newPrice)}
            </Text>
            <Text size="large" color="orange" bold>
              <div className="util-mt-8" />
              {plan?.span === PAYMENT_PERIOD.monthlyPayment ? "/月" : "/年"}
            </Text>
            <Text size="large" color="orange" bold>
              <div className="util-mt-8" />
              {plan?.span === PAYMENT_PERIOD.monthlyPayment
                ? `(${campaign.term}ヶ月間 -${convertToCommaSeparatedString(
                    campaign.discount_price,
                  )}円)`
                : `(${campaign.term}年間 -${campaign.discount_price}円)`}
            </Text>
          </LayoutBox>
          <Text color="neutralUserLight">{`通常価格:${convertToCommaSeparatedString(
            plan.price,
          )}円${
            plan?.span === PAYMENT_PERIOD.monthlyPayment ? "/月" : "/年"
          }`}</Text>
          <Text>{plan?.detail}</Text>
        </LayoutBox>
      ),
      value: String(plan?.span),
    }));
  }

  return plans.map((plan) => ({
    label: (
      <LayoutBox gap="1x" direction="column" fullWidth>
        <Text size="xl" bold>
          {plan?.span === PAYMENT_PERIOD.monthlyPayment
            ? "月払いコース"
            : "年払いコース"}
        </Text>
        <LayoutBox gap="1/2x" fullWidth>
          <Text size="2xl" color="orange" bold>
            {plan?.price ? convertToCommaSeparatedString(plan?.price) : ""}円
          </Text>
          <Text size="large" color="orange" bold>
            <div className="util-mt-8" />
            {plan?.span === PAYMENT_PERIOD.monthlyPayment ? "/月" : "/年"}
          </Text>
        </LayoutBox>
        <Text>{plan?.detail}</Text>
      </LayoutBox>
    ),
    value: String(plan?.span),
  }));
};

const USA024 = () => {
  const [paymentPeriodId] = usePaymentPeriodId();

  const { subscriptionPlans, fetchError, campaignResult, error, apiError } =
    useUSA024DbActions();

  // エラー処理
  useCheckErrorThrowError([fetchError, error, apiError]);

  // キャンペーンの場合、ラジオボタン初期セット
  useEffect(() => {
    if (campaignResult) {
      setPaymentPeriodId(String(campaignResult.payment_span));
    }
  }, [campaignResult]);

  const isCampaign = !!campaignResult;

  const switchPaymentPeriod = (value: unknown) => {
    const selectedPaymentPeriod = Number(value) as ValueOf<
      typeof PAYMENT_PERIOD
    >;
    setPaymentPeriodId(String(selectedPaymentPeriod));
  };

  const navigate = useNavigateWithUrl();
  const goBackToPage = () => navigate(PATIENT.USA006);

  const onNextButtonClicked = () => {
    setPaymentPeriodId(String(paymentPeriodId));
    navigate(PATIENT.USA042);
  };

  const transitionURL = useCurrentLocationState();
  useEffect(() => {
    // ページアクセス不正の場合
    const isInvalidRedirect =
      transitionURL.from !== PATIENT.USA006 &&
      window.location.pathname !== PATIENT.USA024; // 決済画面から遷移した場合
    // 入力項目が不正の場合
    const isInvalidInput = checkUserRegisterInputError();
    const shouldRedirect = isInvalidRedirect || isInvalidInput;
    if (shouldRedirect) redirectToInvalidAccessPage();
  }, [transitionURL]);

  return (
    <div className="admin-area admin-area_no-header">
      <div className="admin-inner area-with-header-footer">
        <HeaderUser withRightControl={false} />
        <StepBar currentStepId="2" steps={REGISTER_STEP_ITEMS} />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="お支払いコースの選択"
          withButton={false}
          borderBottom="primary"
        />
        <Sheet padding="40px 0">
          <LayoutBox
            direction="column"
            justify="center"
            align="center"
            fullWidth
            gap="2x"
          >
            <Sheet
              type="card"
              padding="16px 16px 32px 16px"
              maxWidth="1063px"
              minWidth="343px"
            >
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox direction="column" fullWidth>
                  <Text size="2xl" bold>
                    サービス内容
                  </Text>
                  <Divider borderWidth="3px" margin="0" />
                </LayoutBox>
                <LayoutBox gap="2x" direction="column" fullWidth>
                  <LayoutBox gap="1x" fullWidth>
                    <Icon icon="check" color="#CF4900" />
                    <LayoutBox direction="column" gap="none" fullWidth>
                      <Text size="xl" bold>
                        各種検査メニュー
                      </Text>
                      <BulletList
                        items={SERVICE_CONTENT_INSPECTION_ITEMS}
                        color="red"
                      />
                    </LayoutBox>
                  </LayoutBox>
                  <LayoutBox gap="1x" fullWidth>
                    <Icon icon="check" color="#CF4900" />
                    <LayoutBox direction="column" gap="none" fullWidth>
                      <Text size="xl" bold>
                        各種相談フォローアップメニュー
                      </Text>
                      <BulletList
                        items={SERVICE_CONTENT_CONSULTATION_ITEMS}
                        color="red"
                      />
                    </LayoutBox>
                  </LayoutBox>
                  <LayoutBox gap="1x" fullWidth>
                    <Icon icon="check" color="#CF4900" />
                    <LayoutBox direction="column" gap="none" fullWidth>
                      <Text size="xl" bold>
                        追加精密検査
                      </Text>
                      <BulletList
                        items={SERVICE_CONTENT_DETAILED_INSPECTION_ITEMS}
                        color="red"
                      />
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <Sheet
              type="card"
              padding="16px 16px 32px 16px"
              maxWidth="1063px"
              minWidth="343px"
            >
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox direction="column" fullWidth>
                  <Text size="2xl" bold>
                    お支払いコース
                  </Text>
                  <Divider borderWidth="3px" margin="0" />
                </LayoutBox>
                <Text size="xl">お支払いコースを選択してください。</Text>
                <RadioButtonGroup
                  items={getRadioButtonItem(subscriptionPlans, campaignResult)}
                  selectedValue={String(paymentPeriodId)}
                  name="RB Items"
                  onChange={switchPaymentPeriod}
                  withBorder
                  alignItems="center"
                  width="492px"
                  column={2}
                  radioGap="8px"
                  wrap={!isCampaign}
                  wrapContentStart={isCampaign}
                />
              </LayoutBox>
            </Sheet>
            <LayoutBox
              justify="between"
              maxWidth="1063px"
              fullWidth
              wrapReverse
              gap="2x"
              className="payment-method_centered-buttons"
            >
              <SpButton
                type="secondary"
                width="400px"
                size="medium"
                withArrowLeft
                onClick={goBackToPage}
              >
                戻る
              </SpButton>
              <SpButton
                type="primary"
                width="400px"
                size="medium"
                icon="open_in_new"
                iconSize="large"
                withArrowRight
                onClick={onNextButtonClicked}
                disabled={!paymentPeriodId}
              >
                確認
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA024;
