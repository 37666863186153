import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PetStatusID } from "../constants/common";
import { PATIENT } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import usePatientPetFetch from "../hooks/common/usePatientPetFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  isWithinExaminationPeriod,
  formatDate,
  getNextExaminationStartDate,
  getUserYearSinceActivation,
} from "../utils/utils";

const PetCard = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const { patientPet, error: petGetError } = usePatientPetFetch();

  // エラー処理
  useCheckErrorThrowError([petGetError]);

  // MRI,PETが受診可能か
  const isMriPetWithinExaminationPeriod = isWithinExaminationPeriod(
    realmAppContext.currentUser,
  );

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    realmAppContext.currentUser,
  );

  // MRI、PET次回開始日取得
  const nextExaminationStartDate = getNextExaminationStartDate(
    realmAppContext.currentUser,
    userYearSinceActivation,
  );

  const renderContent = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];

      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
          return (
            <Text size="large" color="neutralUserLight">
              予約調整中...
            </Text>
          );
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {petObj.fix_book_date || ""}
              </Text>
              <Text size="large" color="neutralUserLight">
                {petObj.hospital_name}
              </Text>
            </>
          );
        case PetStatusID.NO_EXAMINATION:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {petObj.fix_book_date || ""} (受検なし)
              </Text>
              <Text size="large" color="neutralUserLight">
                {petObj.hospital_name}
              </Text>
            </>
          );
        case PetStatusID.USER_REPORTED:
          if (
            isMriPetWithinExaminationPeriod &&
            !isWithinExaminationPeriod(
              realmAppContext.currentUser,
              petObj.fix_book_date_start,
            )
          ) {
            return (
              <Text size="large" color="neutralUserLight">
                MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
              </Text>
            );
          }

          return (
            <>
              <Text size="large" color="neutralUserLight">
                FDG PET検査は年に1回受検できます。
              </Text>
              <Text size="large" color="neutralUserLight">
                次回:{formatDate(nextExaminationStartDate)}以降
              </Text>
            </>
          );

        default:
          return (
            <Text size="large" color="neutralUserLight">
              MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
            </Text>
          );
      }
    } else {
      return (
        <>
          <Text size="large" color="neutralUserLight">
            MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
          </Text>
        </>
      );
    }
  };

  const getOnClickHandler = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];
      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
          return () => navigate(PATIENT.USC018);
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
        case PetStatusID.NO_EXAMINATION:
        case PetStatusID.USER_REPORTED:
          return () => navigate(PATIENT.USC003);
        default:
          return () => navigate(PATIENT.USC012);
      }
    } else {
      return () => navigate(PATIENT.USC012);
    }
  };

  return (
    <Card
      header={
        <>
          <Text bold size="xl">
            FDG-PET検査(別途料金)
          </Text>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <LayoutBox direction="column" gap="1x">
          {renderContent()}
        </LayoutBox>
      }
      onClick={getOnClickHandler()}
      clickable={isMriPetWithinExaminationPeriod}
      minHeight="mh-85px"
    />
  );
};
export default PetCard;
