import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
} from "../../../constants/common";
import {
  getAggregateUsaInvitationsUser,
  getMongoDb,
} from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useUserRegisterInput from "../../input/useUserRegisterInput";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const genderObject = {
  male: "1",
  female: "2",
} as const;

const useUsa006DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<PatientInvitation>();
  const [error, setError] = useState<Error | null>(null);

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      pastDiagnosis,
    },
  ] = useUserRegisterInput();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );

        const usa006Aggregate = getAggregateUsaInvitationsUser(
          currentUser?.id ?? "",
          [PATIENT_INVITATIONS_STATUS.notRegistered],
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          usa006Aggregate,
        )) as PatientInvitation[];
        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser]);

  // 会員情報更新 失敗時にはfalseを返します。
  const updateUser = async () => {
    openUpdateActionLoading();
    try {
      // ユーザ情報更新API
      const params = {
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        tel: tel.value,
        zipcode: zipcode.value,
        pref: pref.value,
        city: city.value,
        town1: town1.value,
        town2,
        is_enabled_modality: !enabledModality[0].checked,
        past_diagnosis: !pastDiagnosis[0].checked,
      };
      await currentUser?.functions["account/present/create_invited_user"](
        currentUser?.id,
        params,
      );

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    result,
    error,
    updateUser,
  };
};

export default useUsa006DBActions;
