import { useCallback, useMemo } from "react";
import CardShop from "../components/Card/CardShop";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import CartGroup from "../assets/cart-group-icon.svg";
import Image from "../components/Image/Image";
import { getMinimumSkuAmount } from "../utils/getMinimumSkusAmount";
import useUsf001Actions from "../hooks/pages/USF001/useUsf001DBActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { setId } from "../hooks/common/useId";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const ShopSheet = () => {
  const { result: ecResult, error: ecGetError } = useUsf001Actions();
  const navigate = useNavigateWithUrl();

  // エラー処理
  useCheckErrorThrowError([ecGetError]);

  // EC画像取得
  const getImageUrl = useCallback(
    (imageUrls: string[]) =>
      imageUrls.length > 0
        ? `${imageUrls[imageUrls.length - 1]}?timestamp=${Date.now()}`
        : "",
    [],
  );

  // EC商品詳細へ
  const navigateToItemDetail = useCallback(
    (id: string) => {
      setId(id);
      navigate(PATIENT.USF005);
    },
    [navigate],
  );

  // EC 最大3件表示
  const cardShopItems = useMemo(
    () =>
      (ecResult?.recommendation_items ?? []).slice(0, 3).map((item) => ({
        title: item.item_name,
        price: getMinimumSkuAmount(item.skus_info).toLocaleString(),
        image: getImageUrl(item.image_urls),
        alt: item.item_name,
        onClick: () => navigateToItemDetail(item._id),
      })),
    [getImageUrl, navigateToItemDetail, ecResult?.recommendation_items],
  );

  return (
    <Sheet color="rough-blue-arch" padding="30px 0 24px 0" minWidth="1076px">
      <LayoutBox width="1064px" minWidth="1064px" justify="center">
        <LayoutBox direction="column" align="center" gap="3x">
          <LayoutBox direction="column" align="center">
            <LayoutBox justify="center">
              <Image url={CartGroup} height="100%" width="100%" />
            </LayoutBox>
            <LayoutBox direction="column" align="center" gap="1x">
              <Text size="xl" color="orange" bold>
                日常でも認知症予防をしたい！そんな方に
              </Text>
              <Text size="3xl" lineHeight="150%" titleFont bold>
                オンラインショップ
              </Text>
            </LayoutBox>
          </LayoutBox>
          <CardShop
            cardShop={cardShopItems}
            withButton
            onClickWithButton={() => navigate(PATIENT.USF004)}
          />
        </LayoutBox>
      </LayoutBox>
    </Sheet>
  );
};
export default ShopSheet;
