import Divider from "../components/Divider/Divider";
import HeaderUser from "../components/Header/HeaderUser";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { useCheckErrorThrowError } from "../utils/checkError";
import useGetPaidInterview from "../hooks/common/useGetPaidInterview";

const USD008 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const { interview, error } = useGetPaidInterview();

  useCheckErrorThrowError([error]);

  const onBackBtnClicked = () => {
    navigateWithBack();
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.healthAdvice}
          minWidth="1076px"
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="面談予約"
          content="Zoomを通して医師に健康相談を行うことができます"
          borderBottom="primary"
          onClickBack={onBackBtnClicked}
        />
        <LayoutBox justify="center">
          <Sheet padding="24px 16px 0px" maxWidth="1096px">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="24px 16px">
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox
                      direction="column"
                      align="center"
                      gap="2x"
                      fullWidth
                    >
                      <Icon icon="warning" size="xl" color="#D6A000" />
                      <Text size="2xl" bold color="neutralUserBlack">
                        現在予約を調整中です。
                      </Text>
                    </LayoutBox>
                    <Divider darkColor margin="0" />
                    {interview && interview.candidate_interview_dates && (
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        {Object.entries(
                          interview.candidate_interview_dates,
                        ).map(([key, value], index) =>
                          value ? (
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              fullWidth
                              key={key}
                            >
                              <Text size="xl" bold color="neutralUserBlack">
                                第{index + 1}候補
                              </Text>
                              <Text size="xl" color="neutralUserBlack">
                                {value}
                              </Text>
                              <Divider darkColor margin="0" />
                            </LayoutBox>
                          ) : null,
                        )}
                      </LayoutBox>
                    )}
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};
export default USD008;
