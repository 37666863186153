import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import SpButton from "../components/Button/SpButton";

import Divider from "../components/Divider/Divider";
import Icon from "../components/Icon/Icon";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import usePatientInterviewsFetch from "../hooks/common/usePatientInterviewsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  CONSULTATION_PHONE_NUMBER,
  PatientInterviewStatusID,
} from "../constants/common";

const USD002 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const { patientInterviews, error } = usePatientInterviewsFetch();
  const confirmedInterviews =
    patientInterviews?.filter(
      (interview) =>
        interview.status === PatientInterviewStatusID.CONFIRMED_RESERVATION,
    ) ?? [];

  // エラー処理
  useCheckErrorThrowError([error]);

  // zoomを開く
  const handleOpenZoom = () => {
    const zoomLink = confirmedInterviews[0]?.zoom_link;
    if (zoomLink) {
      window.open(zoomLink, "_blank");
    }
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.healthAdvice}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="面談予約"
          content="Zoomを通して医師に健康相談を行うことができます"
          borderBottom="primary"
          onClickBack={navigateWithBack}
        />
        <div className=" util-full-width util-py-40 util-sp-py-24">
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox direction="column" gap="3x" width="1064px">
              <Sheet
                type="card"
                padding="16px 16px 24px 16px"
                className="util-sp-pt-16"
              >
                <LayoutBox gap="3x" direction="column" fullWidth>
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    align="start"
                    fullWidth
                  >
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      面談
                    </Text>
                    <LayoutBox
                      justify="between"
                      align="start"
                      fullWidth
                      className="util-sp-flex--column"
                      gap="1x"
                    >
                      <Text size="xl" color="neutralLight" lineHeight="150%">
                        {confirmedInterviews.length > 0 && (
                          <>
                            {confirmedInterviews[0].interviewFixDateFromTo}
                            <br />
                            場所： オンライン
                          </>
                        )}
                      </Text>
                      {confirmedInterviews.length > 0 && (
                        <SpButton
                          type="primary"
                          width="311px"
                          size="small"
                          mobileFullWidth
                          onClick={handleOpenZoom}
                        >
                          Zoomを開く
                        </SpButton>
                      )}
                    </LayoutBox>
                  </LayoutBox>
                  <Divider orange={false} darkColor margin="0" />
                  <LayoutBox
                    gap="1x"
                    direction="column"
                    fullWidth
                    align="start"
                    className="util-sp-flex--align-center"
                  >
                    <Text size="large">
                      Zoomが開けないなど、お困りの際は下記にお問い合わせください。
                    </Text>
                    <LayoutBox gap="1x">
                      <Icon icon="phone" />
                      <Text size="xl" bold>
                        {CONSULTATION_PHONE_NUMBER}
                      </Text>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USD002;
