import { useEffect, useMemo } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Button from "../components/Button/Button";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import FormSet from "../components/FormSet/FormSet";
import Banner from "../components/Banner/Banner";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import useGetYoseVideoActions from "../hooks/common/useGetYoseVideoActions";
import {
  EmbeddedPageID,
  YOSE_STATUS,
  YOSE_TAB_MENUS,
} from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import useGetCampaigns from "../hooks/common/useGetCampaigns";

import USE002ImageContainer from "../components/Image/USE002/ImageContainer";
import SpPaginationContainer from "../components/Pagination/SpPaginationContainer";
import usePagination, {
  setPage,
  setTotal,
} from "../hooks/component/pagination/usePagination";
import { setId } from "../hooks/common/useId";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { useSelectedTabMenu } from "../hooks/pages/USE002/USE002ReactiveVar";
import PatientFooter from "../features/PatientFooter";

const SP_TAB_ITEMS = [
  {
    id: YOSE_TAB_MENUS.public,
    label: "公開中",
  },
  {
    id: YOSE_TAB_MENUS.close,
    label: "公開終了",
  },
];

const USE002 = () => {
  const navigate = useNavigateCustom();
  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.YOSE_LIST,
  });

  // 公開中タブor公開終了タブの選択状態
  const [selectedYoseTabMenu, setSelectedYoseTabMenu] = useSelectedTabMenu();

  // 動画を取得します。
  const [pagination] = usePagination();
  const { skip } = pagination;
  const statusDisplayed = useMemo(
    () =>
      selectedYoseTabMenu === YOSE_TAB_MENUS.public
        ? [YOSE_STATUS.newPeriod, YOSE_STATUS.publicOpen]
        : [YOSE_STATUS.publicClose],
    [selectedYoseTabMenu],
  );
  const {
    videos,
    count,
    error: yoseGetError,
  } = useGetYoseVideoActions({
    statusDisplayed,
    skip,
  });

  const handleTabChange = (index: number | string) => {
    // コンポーネントの都合で型変換
    const _index = index as unknown as ValueOf<typeof YOSE_TAB_MENUS>;

    setSelectedYoseTabMenu(_index);

    const paginationLocation = 1;
    setPage(paginationLocation);
  };

  const handleClickBack = () => {
    navigate(PATIENT.USB001);
  };

  const onWatchVideoBtnClicked = (id: string) => {
    setId(id);
    navigate(PATIENT.USE004);
  };

  const getTabMenu = (status: ValueOf<typeof YOSE_STATUS>) => {
    if (status === YOSE_STATUS.newPeriod) return "new";

    if (status === YOSE_STATUS.publicClose) return "end";

    // 公開中
    return undefined;
  };

  // エラー処理
  useCheckErrorThrowError([yoseGetError, campaignsGetError]);

  useEffect(() => {
    setTotal(count);
  }, [count]);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.yose} />
        <div className="util-flex util-full-width util-flex--column util-flex--gap40 util-flex--align-start util-sp-flex--gap24">
          <Title
            padding="16px 108px 24px 108px"
            background="arc"
            header="オリジナル落語動画"
            content="オンラインで視聴可能なオリジナル落語動画を、定期的にご紹介します"
            minWidth="1076px"
            onClickBack={handleClickBack}
          />
          {campaigns.top && (
            <Banner
              url={campaigns.top.url}
              imagePc={campaigns.top.pc_image}
              imageSp={campaigns.top.sp_image}
            />
          )}
          <Sheet minWidth="1076px" padding="0">
            <LayoutBox direction="column" gap="2x" width="1064px">
              <ToggleButton
                size="large"
                width="100%"
                items={SP_TAB_ITEMS}
                selectedButton={selectedYoseTabMenu}
                onClick={handleTabChange}
              />
              <LayoutBox gap="2x" maxWidth="1064px" wrap>
                {videos.map((video, index) => (
                  <LayoutBox
                    width="343.67px"
                    className="util-sp-full-width"
                    key={index}
                  >
                    <Sheet
                      type="card"
                      color="white"
                      borderRadius="8px"
                      withCornerText={getTabMenu(video.status)}
                      padding="15px"
                      relative
                    >
                      <LayoutBox
                        gap="1x"
                        direction="column"
                        fullWidth
                        align="center"
                      >
                        <LayoutBox
                          gap="1x"
                          direction="column"
                          fullWidth
                          maxWidth="311px"
                          align="center"
                          justify="center"
                        >
                          <USE002ImageContainer vimeoId={video.vimeo_id} />
                        </LayoutBox>

                        <FormSet
                          base
                          label="落語家"
                          labelSize="small"
                          labelWidth="74px"
                        >
                          <Text size="xl">{video.cast}</Text>
                        </FormSet>
                        <Text
                          bold
                          size="1xl"
                          ellipsis
                          ellipsisLine="2"
                          titleFont
                        >
                          {video.yose_description}
                        </Text>
                        <LayoutBox justify="center">
                          <Button
                            type="sub"
                            shape="circle"
                            width="311px"
                            underlined
                            size="small"
                            onClick={() => onWatchVideoBtnClicked(video._id)}
                          >
                            動画を見る
                          </Button>
                        </LayoutBox>
                      </LayoutBox>
                    </Sheet>
                  </LayoutBox>
                ))}
                <Sheet padding="0">
                  <SpPaginationContainer />
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          {campaigns.bottom && (
            <Banner
              url={campaigns.bottom.url}
              imagePc={campaigns.bottom.pc_image}
              imageSp={campaigns.bottom.sp_image}
            />
          )}
        </div>
        <div className="util-mb-72 util-sp-mb-38" />
      </div>
      <PatientFooter />
    </div>
  );
};
export default USE002;
