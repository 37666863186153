/* eslint-disable @typescript-eslint/no-unused-vars */

import { format } from "date-fns";
import {
  DeliveryMethodID,
  OrderStatusID,
  ModalityID,
  HospitalReservationStatusID,
  HospitalInspectionStatusID,
  PatientTypeID,
  PlanSpanID,
} from "../constants/common";
import { redirectToInvalidFunctionPage } from "../contexts/CustomErrorBoundary";

// 性別を表示用に変換
const _convertGender = (gender: string) => {
  switch (gender) {
    case "male":
      return "男性";
    case "female":
      return "女性";
    default:
      return "その他";
  }
};

// フォーマットに合わせて日付を変換
export const convertDate = (dateStr: Date, formatStr = "yyyy/MM/dd") => {
  const date = new Date(dateStr);

  return format(date, formatStr);
};

// ステータスから午前、午後、終日に変換
const _getDayStatus = (day: DayStatus) => {
  if (day.AM === 1 && day.PM === 1) {
    return "終日";
  }
  if (day.AM === 1 && day.PM === 0) {
    return "午前";
  }
  if (day.AM === 0 && day.PM === 1) {
    return "午後";
  }

  return "";
};

// 営業日、定休日を取得
const _getBusinessAndClosedDays = (
  data: HospitalWeek,
): { closed: string; open: string } => {
  const dayMap: { [key: string]: string } = {
    mon: "月",
    tue: "火",
    wed: "水",
    thu: "木",
    fri: "金",
    sta: "土",
    sun: "日",
  };

  const closedDays = Object.entries(data)
    .filter(([_, value]) => value.AM === 0 && value.PM === 0)
    .map(([key]) => dayMap[key]);

  const openDays = Object.entries(data)
    .filter(([_, value]) => value.AM === 1 || value.PM === 1)
    .map(([key]) => dayMap[key]);

  return {
    closed: closedDays.join("、"),
    open: openDays.join("、"),
  };
};

// 診療時間を表示用に変換
const _convertHospitalHours = (hours: HospitalHour): string => {
  const amString = `${hours.am.start}-${hours.am.end}`;
  const pmString = `${hours.pm.start}-${hours.pm.end}`;

  return `${amString}/${pmString}`;
};

// 現在の日付と引数の日付の差分変換
const _convertDateDifferenceToCurrent = (updatedAt: Date) => {
  const now = new Date();

  // 現在の日付とupdatedAtの日付をYYYY-MM-DDの形式で比較
  const nowDateStr = now.toISOString().slice(0, 10);
  const updatedAtDateStr = updatedAt.toISOString().slice(0, 10);

  if (nowDateStr === updatedAtDateStr) {
    // 本日の場合、hh:mmの形式
    return updatedAt.toISOString().slice(11, 16);
  }
  // 前日以前の場合、〇日前
  const timeDifference = now.getTime() - updatedAt.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return `${dayDifference}日前`;
};

// 金額変換
const _convertToCurrency = (value: number) => {
  const stringValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `¥${stringValue}`;
};

// カンマ区切りに変換
const _convertToCommaSeparatedString = (value: number) =>
  value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// 配達方法変換
const _convertDeliveryMethod = (
  deliveryMethodNum: number,
  deliveryDate: string | null,
) => {
  switch (deliveryMethodNum) {
    case DeliveryMethodID.NORMAL:
      return "通常配送";
    case DeliveryMethodID.SPECIFIED:
      if (!deliveryDate) return redirectToInvalidFunctionPage();

      return `お届け日指定:${deliveryDate}`;
    default:
      return redirectToInvalidFunctionPage();
  }
};

// 注文ステータス変換
const _convertOrderStatusStr = (status: number) => {
  switch (status) {
    case OrderStatusID.PAYMENT_COMPLETED:
      return "支払完了";
    case OrderStatusID.SHIPPED:
      return "出荷済";
    case OrderStatusID.DELIVERY_COMPLETED:
      return "配送完了";
    default:
      return "";
  }
};

// 検査内容変換
const _convertModalityStr = (status: number) => {
  switch (status) {
    case ModalityID.MRI:
      return "MRI検査";
    case ModalityID.PET:
      return "PET検査";
    default:
      return "";
  }
};

// 予約受付ステータス変換
const _convertHospitalReservationStatusStr = (status: number) => {
  switch (status) {
    case HospitalReservationStatusID.NEW:
      return "新規予約";
    case HospitalReservationStatusID.CONFIRM:
      return "確定";
    case HospitalReservationStatusID.NG:
      return "NG";
    case HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL:
      return "取り下げ申請";
    case HospitalReservationStatusID.WITHDRAWAL:
      return "取り下げ";
    default:
      return "";
  }
};

// 未検査、検査変換
const _convertHospitalInspectionStatusStr = (status: number) => {
  switch (status) {
    case HospitalInspectionStatusID.NOT_INSPECTED:
      return "未検査";
    case HospitalInspectionStatusID.INSPECTED:
      return "検査済";
    default:
      return "";
  }
};

// 文字列の時間を午前・午後に変換
const _convertToAmPmStr = (dateTime: string): string => {
  // "HH:MM" の部分を取得
  const timePart = dateTime.split(" ")[1];
  const hour = parseInt(timePart.split(":")[0], 10);

  if (hour < 12) {
    // "YYYY/MM/DD午前"
    return `${dateTime.split(" ")[0]}午前`;
  }

  // "YYYY/MM/DD午後"
  return `${dateTime.split(" ")[0]}午後`;
};

type SearchInfo<IDType> = {
  id: IDType;
  label: string;
  flgKey: string;
};
// ステータスの番号を文字列に変換
const convertStatusStr = <IDType>(
  statusNumber: number,
  searchInfo: SearchInfo<IDType>[],
  statusIds: { [key: string]: IDType },
): string => {
  const statusNum = statusNumber as IDType;
  if (!(statusNumber in statusIds)) {
    return redirectToInvalidFunctionPage();
  }

  const foundStatus = searchInfo.find(({ id }) => id === statusNum);

  if (!foundStatus) {
    return redirectToInvalidFunctionPage();
  }

  return foundStatus.label;
};

export const convertUsg005 = (user: PatientsType | null) => {
  if (user) {
    const {
      _id: id,
      name_sei: nameSei,
      name_mei: nameMei,
      name_sei_kana: nameSeiKana,
      name_mei_kana: nameMeiKana,
      gender,
      birth_year: birthYear,
      birth_month: birthMonth,
      birth_day: birthDay,
      tel,
      email,
      is_enabled_modality: isEnabledModality,
      is_notification: isNotification,
      zipcode,
      pref,
      city,
      town1,
      town2,
      span,
      price: priceNum,
      campaign_code: campaignCode,
      discount_rate: discountRate,
      discount_price: discountPrice,
      term,
    } = user;

    const invitation = campaignCode || "なし";
    const planType =
      span && priceNum
        ? `${
            span === PlanSpanID.YEAR ? "年払いコース:" : "月払いコース:"
          }${_convertToCommaSeparatedString(priceNum)}円${
            span === PlanSpanID.YEAR ? "" : "/月"
          }`
        : "";
    const campaignDisplay = campaignCode
      ? discountPrice
        ? `(${term}ヶ月 - ${discountPrice}円)`
        : `(${term}ヶ月 - ${discountRate}%)`
      : "";

    return {
      id,
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender: _convertGender(gender),
      birth: `${birthYear}年${birthMonth}月${birthDay}日`,
      tel,
      zipcode,
      address: `${pref}${city}${town1}${town2 || ""}`,
      email,
      mriPetConditions: isEnabledModality ? "当てはまる" : "当てはまらない",
      notification: isNotification ? "希望する" : "希望しない",
      invitation,
      planType: `${planType} ${campaignDisplay}`,
    };
  }

  return {
    id: "",
    nameSei: "",
    nameMei: "",
    nameSeiKana: "",
    nameMeiKana: "",
    gender: "",
    birth: "",
    tel: "",
    zipcode: "",
    address: "",
    email: "",
    mriPetConditions: "",
    notification: "",
    invitation: "",
    planType: "",
  };
};
