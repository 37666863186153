import React, { useEffect, useState } from "react";
import SpButton from "../components/Button/SpButton";
import CheckboxGroup from "../components/Checkbox/CheckboxGroup";
import Divider from "../components/Divider/Divider";
import SpFormSet from "../components/FormSet/SpFormSet";
import HeaderUser from "../components/Header/HeaderUser";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Link from "../components/Link/Link";
import BulletList from "../components/List/BulletList";
import Sheet from "../components/Sheet/Sheet";
import StepBar from "../components/StepBar/StepBar";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import usePaymentPeriodId from "../hooks/common/usePaymentPeriod";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useUSA024DbActions from "../hooks/pages/USA024/useUSA024DbActions";
import {
  FORMAT_STYLE,
  mobileView,
  PAYMENT_PERIOD,
  PAYMENT_SPAN,
  REGISTER_STEP_ITEMS,
  SERVICE_CONTENT_CONSULTATION_ITEMS,
  SERVICE_CONTENT_DETAILED_INSPECTION_ITEMS,
  SERVICE_CONTENT_INSPECTION_ITEMS,
} from "../constants/common";
import PatientFooter from "../features/PatientFooter";
import { convertToCommaSeparatedString, formatDate } from "../utils/utils";
import usePolicyChecks, {
  setPolicyChecks,
} from "../hooks/common/usePolicyChecks";
import usePath from "../hooks/common/usePath";

const CHECKBOX_ITEMS = [
  {
    label: (
      <>
        <Link color="primary">利用規約</Link>
        <Text size="xl">に同意する</Text>
      </>
    ),
    checked: false,
    value: "1",
  },
  {
    label: (
      <>
        <Link color="primary">プライバシーポリシー</Link>
        <Text size="xl">に同意する</Text>
      </>
    ),
    checked: false,
    value: "2",
  },
];

const USA042 = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState(CHECKBOX_ITEMS);
  const [paymentPeriodId] = usePaymentPeriodId();
  const [policyChecks] = usePolicyChecks();
  const navigate = useNavigateWithUrl();
  const { registrationUrls, reEntryUrls, subscriptionPlans, campaignResult } =
    useUSA024DbActions();
  const [path] = usePath();

  // チェックボックスのstate
  useEffect(() => {
    if (policyChecks.length > 0) {
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckboxItems = prevCheckedItems.map((item, index) => {
          const check = policyChecks[index];

          return index < policyChecks.length
            ? { ...item, checked: check }
            : item;
        });

        return updatedCheckboxItems;
      });
    }
  }, [policyChecks, setCheckedItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let url = "";
  if (path === PATIENT.USB001) {
    url =
      paymentPeriodId === String(PAYMENT_PERIOD.monthlyPayment)
        ? reEntryUrls.monthlyPaymentUrl
        : reEntryUrls.annualPaymentUrl;
  } else {
    url =
      paymentPeriodId === String(PAYMENT_PERIOD.monthlyPayment)
        ? registrationUrls.monthlyPaymentUrl
        : registrationUrls.annualPaymentUrl;
  }

  const isCampaign = !!campaignResult;

  // チェックボックス
  const handleCheckboxChange = (value: string) => {
    const updatedCheckboxItems = checkedItems.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );
    setCheckedItems(updatedCheckboxItems);
    setPolicyChecks(updatedCheckboxItems.map((item) => item.checked));
  };

  // プラン情報
  const plan = subscriptionPlans.find(
    (value) => value.span === Number(paymentPeriodId),
  );

  // 戻るボタン
  const handleGoBack = () => navigate(PATIENT.USA024);

  // 次へボタン
  const onNextButtonClicked = () => {
    window.location.href = url;
  };

  // 今回お支払い金額
  const campaignPrice = (
    campaign: USA024Type | undefined,
    price: number,
  ): string => {
    if (!campaign) {
      return `税込 ${convertToCommaSeparatedString(price)}円`;
    }
    if (campaign.discount_price) {
      return `税込 ${convertToCommaSeparatedString(
        price - campaign.discount_price,
      )}円`;
    }

    return `税込 ${convertToCommaSeparatedString(price)}円`;
  };

  // 次回お支払い金額(月払い)
  const nextPriceMonthly = (
    campaign: USA024Type | undefined,
    price: number,
  ): string => {
    if (campaign) {
      // termが2カ月以上かどうか判定
      if (campaign.term >= 2) {
        if (campaign.discount_price) {
          return `税込 ${convertToCommaSeparatedString(
            price - campaign.discount_price,
          )}円 (キャンペーン適用)`;
        }
      }

      return `税込 ${convertToCommaSeparatedString(price)}円`;
    }

    return `税込 ${convertToCommaSeparatedString(price)}円`;
  };

  // 次回お支払い金額(年払い)
  const nextPriceAnnual = (
    campaign: USA024Type | undefined,
    price: number,
  ): string => {
    if (campaign) {
      // termが2年以上かどうか判定
      if (campaign.term >= 2) {
        if (campaign.discount_price) {
          return `税込 ${convertToCommaSeparatedString(
            price - campaign.discount_price,
          )}円 (キャンペーン適用)`;
        }
      }

      return `税込 ${convertToCommaSeparatedString(price)}円`;
    }

    return `税込 ${convertToCommaSeparatedString(price)}円`;
  };

  // 翌支払日
  const nextPaymentDate = (span: number | undefined) => {
    const today = new Date();
    if (span === PAYMENT_PERIOD.monthlyPayment) {
      return formatDate(
        new Date(today.setMonth(today.getMonth() + 1)),
        FORMAT_STYLE["YYYY/MM/DD"],
      );
    }

    return formatDate(
      new Date(today.setFullYear(today.getFullYear() + 1)),
      FORMAT_STYLE["YYYY/MM/DD"],
    );
  };

  // キャンペーン備考
  const campaignNote = (campaign: USA024Type): string => {
    const term =
      campaign.payment_span === PAYMENT_SPAN.month
        ? `${campaign.term}ヶ月間`
        : `${campaign.term}年間`;

    return `${term} ${convertToCommaSeparatedString(
      campaign.discount_price,
    )}円引き/`;
  };

  return (
    <div className="admin-area admin-area_no-header">
      <div className="admin-inner area-with-header-footer">
        <HeaderUser withRightControl={false} />
        <StepBar currentStepId="2" steps={REGISTER_STEP_ITEMS} />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="お支払いコースの確認"
          withButton={false}
          borderBottom="primary"
        />
        <Sheet padding="24px 0 0">
          <LayoutBox
            direction="column"
            justify="center"
            align="center"
            fullWidth
            gap="2x"
          >
            <Sheet
              type="card"
              padding="16px 16px 32px 16px"
              maxWidth="1063px"
              minWidth="343px"
            >
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox direction="column" fullWidth>
                  <Text size="2xl" bold color="neutralUserBlack">
                    サービス内容
                  </Text>
                  <Divider borderWidth="3px" margin="0" />
                </LayoutBox>
                <LayoutBox direction="column" gap="none" fullWidth>
                  <Text size="xl" color="neutralUserBlack">
                    {plan?.span === PAYMENT_PERIOD.monthlyPayment
                      ? "本サービスは以下のメニューを提供する毎月定額のサブスクリプション契約となります。"
                      : "本サービスは以下のメニューを提供する毎年定額のサブスクリプション契約となります。"}
                  </Text>
                </LayoutBox>
                {isExpanded && (
                  <LayoutBox gap="2x" direction="column" fullWidth>
                    <LayoutBox gap="1x" fullWidth>
                      <Icon icon="check" color="#CF4900" />
                      <LayoutBox direction="column" gap="1/2x" fullWidth>
                        <Text size="xl" bold>
                          各種検査メニュー
                        </Text>
                        <BulletList
                          items={SERVICE_CONTENT_INSPECTION_ITEMS}
                          color="red"
                        />
                      </LayoutBox>
                    </LayoutBox>
                    <LayoutBox gap="1x" fullWidth>
                      <Icon icon="check" color="#CF4900" />
                      <LayoutBox direction="column" gap="1/2x" fullWidth>
                        <Text size="xl" bold>
                          各種相談フォローアップメニュー
                        </Text>
                        <BulletList
                          items={SERVICE_CONTENT_CONSULTATION_ITEMS}
                          color="red"
                        />
                      </LayoutBox>
                    </LayoutBox>
                    <LayoutBox gap="1x" fullWidth>
                      <Icon icon="check" color="#CF4900" />
                      <LayoutBox direction="column" gap="1/2x" fullWidth>
                        <Text size="xl" bold>
                          追加精密検査
                        </Text>
                        <BulletList
                          items={SERVICE_CONTENT_DETAILED_INSPECTION_ITEMS}
                          color="red"
                        />
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                )}
                <SpButton
                  type="secondary"
                  size="medium"
                  width="100%"
                  icon={
                    isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }
                  onClick={() => setIsExpanded(!isExpanded)}
                  iconSize="large"
                >
                  {isExpanded ? "閉じる" : "すべて表示"}
                </SpButton>
              </LayoutBox>
            </Sheet>
            <Sheet type="card" padding="16px 16px 32px" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth gap="3x">
                <SpFormSet
                  label="お支払いコース"
                  base
                  labelSize="medium"
                  labelWidth="200px"
                  spToColumnAndFullWidth
                  labelGap="large"
                >
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    className="util-mt-8"
                    fullWidth
                  >
                    <Text size="xl" width="100%">
                      {plan?.span === PAYMENT_PERIOD.monthlyPayment
                        ? "月払いコース"
                        : "年払いコース"}
                    </Text>
                    <Divider margin="0" darkColor />
                  </LayoutBox>
                </SpFormSet>
                <SpFormSet
                  label="今回お支払い金額"
                  base
                  labelSize="medium"
                  labelWidth="200px"
                  spToColumnAndFullWidth
                  labelGap="large"
                >
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    className="util-mt-8"
                    fullWidth
                  >
                    <Text size="2xl" width="100%">
                      {plan
                        ? campaignPrice(campaignResult, Number(plan?.price))
                        : null}
                      {isCampaign && (
                        <>
                          {!isMobile && "(キャンペーン適用)"}
                          {isMobile && <div>(キャンペーン適用)</div>}
                        </>
                      )}
                    </Text>
                    <Divider margin="0" darkColor />
                  </LayoutBox>
                </SpFormSet>
                {isCampaign && (
                  <SpFormSet
                    label="キャンペーン内容"
                    base
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    labelGap="large"
                  >
                    <LayoutBox
                      direction="column"
                      gap="1x"
                      className="util-mt-8"
                      fullWidth
                    >
                      <Text size="xl" width="100%">
                        {campaignNote(campaignResult)}
                        {plan?.price && (
                          <>
                            {!isMobile &&
                              `通常金額:税込${convertToCommaSeparatedString(
                                plan?.price,
                              )}円`}
                            {isMobile && (
                              <div>
                                通常金額:税込
                                {convertToCommaSeparatedString(plan?.price)}円
                              </div>
                            )}
                          </>
                        )}
                      </Text>
                      <Divider margin="0" darkColor />
                    </LayoutBox>
                  </SpFormSet>
                )}
                <SpFormSet
                  label="次回以降のお支払いについて"
                  base
                  labelSize="medium"
                  labelWidth="200px"
                  spToColumnAndFullWidth
                  labelGap="large"
                >
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    className="util-mt-8"
                    fullWidth
                  >
                    <LayoutBox direction="column" gap="1/2x">
                      <Text size="large" color="neutralUserLight" width="100%">
                        次回更新日
                      </Text>
                      <Text size="xl" width="100%">
                        {nextPaymentDate(plan?.span)}
                      </Text>
                    </LayoutBox>
                    <Divider margin="0" darkColor />
                  </LayoutBox>
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    className="util-mt-8"
                    fullWidth
                  >
                    <LayoutBox direction="column" gap="1/2x">
                      <Text size="large" color="neutralUserLight" width="100%">
                        次回お支払い金額
                      </Text>
                      <Text size="xl" width="100%">
                        {plan?.span === PAYMENT_PERIOD.monthlyPayment
                          ? nextPriceMonthly(
                              campaignResult,
                              Number(plan?.price),
                            )
                          : nextPriceAnnual(
                              campaignResult,
                              Number(plan?.price),
                            )}
                      </Text>
                    </LayoutBox>
                    <Divider margin="0" darkColor />
                  </LayoutBox>
                </SpFormSet>
              </LayoutBox>
            </Sheet>
            <Sheet
              type="card"
              padding="16px 16px 32px 16px"
              maxWidth="1063px"
              minWidth="343px"
            >
              <LayoutBox direction="column" gap="3x" fullWidth>
                <LayoutBox direction="column" fullWidth>
                  <Text size="2xl" bold color="neutralUserBlack">
                    注意事項
                  </Text>
                  <Divider borderWidth="3px" margin="0" />
                </LayoutBox>
                <Sheet
                  padding="16px"
                  color="neutral-lightest"
                  maxHeight="300px"
                  borderRadius="8px"
                  border
                  overflow
                >
                  <ul className="util-pl-12 util-mb-0 util-full-width">
                    <li>
                      <Text inline size="large" color="neutralUserBlack">
                        契約開始日よりサービスをご利用いただけます。
                      </Text>
                    </li>
                    <li>
                      <Text inline size="large" color="neutralUserBlack">
                        退会しない限り自動で更新されます。自動更新を解約する場合には、次回更新日までに会員情報より退会ください。
                      </Text>
                    </li>
                    <li>
                      <Text inline size="large" color="neutralUserBlack">
                        サービスの使用・不使用・退会忘れなど内容に関わらず、申込のキャンセルはできないため、返金対応は致しかねます。
                      </Text>
                    </li>
                    <li>
                      <Text inline size="large" color="neutralUserBlack">
                        支払い期限および支払い方法については、
                      </Text>
                      <Link color="primary">特定商取引法に基づく表示</Link>
                      <Text inline size="large" color="neutralUserBlack">
                        よりご確認ください。
                      </Text>
                    </li>
                  </ul>
                </Sheet>

                <CheckboxGroup
                  items={checkedItems}
                  withContainer
                  onChecked={handleCheckboxChange}
                />
              </LayoutBox>
            </Sheet>
            <LayoutBox
              justify="between"
              maxWidth="1063px"
              fullWidth
              wrapReverse
              gap="2x"
              className="payment-method_centered-buttons"
            >
              <SpButton
                type="secondary"
                width="400px"
                size="medium"
                withArrowLeft
                onClick={handleGoBack}
              >
                戻る
              </SpButton>
              <SpButton
                type="primary"
                width="400px"
                size="medium"
                icon="open_in_new"
                iconSize="large"
                withArrowRight
                onClick={onNextButtonClicked}
                disabled={checkedItems.some(
                  (checkedItem) => !checkedItem.checked,
                )}
              >
                次へ(カード情報入力)
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA042;
